import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useCallback } from 'react';

import { GenericFailure } from '@playq/services-shared';
import { QueryTagsResponse, TagsSort, TagsSortField } from '@playq/octopus2-admin';
import { Tag } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery, UseQueryOptionsExtended, useQueryPrefetch } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { IQuery } from '/common/models';

const emptyArray: [] = [];

export const tagsManagerQueryKeys: unknown[] = ['corp', 'tagsManager', 'query'];

export const useTagsManagerQuery = (
  query: IQuery<TagsSortField, TagsSort>,
  options?: UseQueryOptionsExtended<QueryTagsResponse | undefined, GenericFailure | Error>
) => {
  const { filterBy, sortBy, iterator } = query;

  const queryClient = useQueryClient();

  const keys = useMemo(() => tagsManagerQueryKeys.concat(iterator, sortBy, filterBy), [filterBy, iterator, sortBy]);

  const { mutate, ...res } = useEitherQuery(keys, () => services2.tagsService.queryTags(iterator, sortBy, filterBy), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [iterator, sortBy, filterBy],
    serviceMethod: services2.tagsService.queryTags.bind(services2.tagsService),
  });

  const mutateAfterTransform = useCallback(
    (transformedTags: Tag[], newTag: Tag) => {
      const nextResponse =
        nextKeys === undefined ? undefined : queryClient.getQueryData<QueryTagsResponse | undefined>(nextKeys);
      mutate((prevResponse) => {
        const entities = prevResponse?.entities;
        if (!entities) {
          return prevResponse;
        }
        const firstTransformedTag = transformedTags[0];
        const newEntities = entities.reduce((acc: Tag[], currentTag) => {
          const shouldTagBeRemoved = transformedTags.some(
            (transformedTag) => transformedTag.key === currentTag.key && transformedTag.value === currentTag.value
          );
          if (!shouldTagBeRemoved) {
            acc.push(currentTag);
          }
          if (currentTag.key === firstTransformedTag.key && currentTag.value === firstTransformedTag.value) {
            acc.push(newTag);
          }
          return acc;
        }, []);

        const newResponse = new QueryTagsResponse(prevResponse.serialize());

        const nextEntities = nextResponse?.entities || [];
        const removedEntitiesQuantity = transformedTags.length - 1;
        newResponse.entities = newEntities.concat(nextEntities.slice(0, removedEntitiesQuantity));
        newResponse.total = prevResponse.total - removedEntitiesQuantity;

        return newResponse;
      });
    },
    [mutate, nextKeys, queryClient]
  );

  return {
    queryKeys: keys,
    ...res,
    tags: res.data?.entities || emptyArray,
    total,
    mutateAfterTransform,
  };
};
