import { UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';

import { GenericFailure } from '@playq/services-shared';
import { FileRevisionID, DownloadSingleResponse } from '@playq/octopus2-files';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

export const fileGetDownloadUrlKeys: unknown[] = ['file', 'get', 'downloadUrl'];

export const useFileGetDownloadUrl = (
  id?: FileRevisionID,
  preview?: boolean,
  options?: UseQueryOptions<DownloadSingleResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => fileGetDownloadUrlKeys.concat(id, preview), [id, preview]);

  const enabled = useMemo(() => options?.enabled !== false && id !== undefined, [options?.enabled, id]);

  const { ...res } = useEitherQuery(
    keys,
    () => services2.filesService.download(id as FileRevisionID, preview ?? false),
    {
      ...options,
      staleTime: Infinity,
      enabled,
    }
  );

  return {
    url: res.data?.url,
    ...res,
  };
};
