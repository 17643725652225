import { UseMutationOptions } from '@tanstack/react-query';

import { ExperimentsPolicy } from '@playq/octopus2-analytics';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const usePolicyExperimentsUpdate = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, ExperimentsPolicy>
) => {
  return useEitherMutation((policy) => services2.experimentsAdminService.updatePolicy(policy), options);
};
