import { services2 } from '/api/services2';

export const flowsService = services2.flowsService;
export const flowsServiceName = flowsService.getClassName();

// the same names as the names of the methods in /common/scripts/definitions/script-hooks.d.ts
export const FLOW_PROMOTION_HOOK_NAME = 'onFlowPromote';
export const FLOW_DEMOTION_HOOK_NAME = 'onFlowDemote';
export const FLOW_SAVE_HOOK_NAME = 'onFlowSave';

export const createFlowMutationKey: unknown[] = ['create', 'flow'];
export const updateFlowMutationKey: unknown[] = ['update', 'flow'];
export const removeFlowMutationKey: unknown[] = ['remove', 'flow'];
