import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { AppExperimentResponse } from '@playq/octopus2-analytics';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IExperimentUpdateOptionsVariables, IUpdateExperimentParams } from './types';
import { appExperimentsService, appExperimentsServiceName, updateExperimentMutationKeys } from './constants';

export const useExperimentUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IExperimentUpdateOptionsVariables,
    AppExperimentResponse | undefined
  >
) => {
  return useEitherMutationScripted<
    IUpdateExperimentParams,
    CommonSuccess,
    IInvokeScriptsHook,
    AppExperimentResponse | undefined
  >({
    serviceMethod: appExperimentsService.update.bind(appExperimentsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appExperimentsServiceName,
      mutationType: 'experiment update',
    },
    options: {
      onError: onResponseError,
      mutationKey: updateExperimentMutationKeys,
      ...options,
    },
  });
};
