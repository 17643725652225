import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { CopyEntityID, CopyEntityCreateData } from '@playq/octopus2-creatives';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { updateCopyMutationKey } from './constants';

export type CopyUpdatePayload = {
  id: CopyEntityID;
  payload: CopyEntityCreateData;
};

export const useCopyUpdate = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, CopyUpdatePayload>
) =>
  useEitherMutation(({ id, payload }) => services2.copiesService.update(id, payload), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: updateCopyMutationKey,
    ...options,
  });
