import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GetPermTargetsResponse, PermModuleTargets, PermTarget } from '@playq/octopus2-admin';
import { GenericFailure } from '@playq/services-shared';
import { PermsScope, PermModuleHelpers, PermModule } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { usePermGetTargetsData } from './usePermGetTargetsData';

export const permsTargetsQueryTags: unknown[] = ['perms', 'getPermTargets'];

const usePerms = (data?: GetPermTargetsResponse) => {
  return useMemo(() => {
    if (data === undefined) {
      return;
    }
    const permTargetsResponse = new GetPermTargetsResponse();

    permTargetsResponse.modules = data.modules;

    const responseModule = data.modules;
    permTargetsResponse.modules = PermModuleHelpers.all.reduce((acc: PermModuleTargets[], module: PermModule) => {
      if (module === PermModule.None) {
        return acc;
      }

      const foundModule = responseModule.find((pt: PermModuleTargets) => pt.module_ === module);
      if (foundModule) {
        acc.push(foundModule);
      } else {
        const permModTarget = new PermModuleTargets({ module, targets: [] });
        acc.push(permModTarget);
      }

      return acc;
    }, []);

    return permTargetsResponse;
  }, [data]);
};

export const usePermGetTargets = (
  scope?: PermsScope,
  withTargetsNames = false,
  options?: UseQueryOptions<GetPermTargetsResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => permsTargetsQueryTags.concat(scope), [scope]);
  const res = useEitherQuery(
    keys,
    () => {
      // check if scope is not undefined added to enabled option
      return services2.adminPermsService.getPermTargets(scope as PermsScope);
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      enabled: (options?.enabled ?? true) && !!scope,
      ...options,
    }
  );

  const perms = usePerms(res.data);

  const targetsForNames: PermTarget[] = useMemo(() => {
    if (!withTargetsNames || !perms?.modules) {
      return [];
    }
    const permsTargets = perms.modules.reduce((acc: PermTarget[], module) => {
      const { targets } = module;
      targets.forEach(({ target }) => {
        acc.push(target);
      });
      return acc;
    }, []);

    return Array.from(new Set(permsTargets));
  }, [perms?.modules, withTargetsNames]);

  const targetsData = usePermGetTargetsData(targetsForNames);

  const targetsNames = useMemo(
    () =>
      targetsData.data?.reduce((acc: { [id: string]: string }, cur) => {
        if (cur !== undefined) {
          acc[cur.id.serialize()] = cur.name;
        }
        return acc;
      }, {}),
    [targetsData]
  );

  return {
    ...res,
    perms,
    tags: keys,
    targetsNames,
  };
};
