import { BlueprintEntityID } from '@playq/octopus2-analytics';

import { mockBlueprintID } from './constants';
import { IGetBlueprintIDForQueryParams } from './types';

export const getBlueprintIDForQuery = ({
  appID,
  blueprintID: blueprintIDNum,
  isQueryEnabled,
}: IGetBlueprintIDForQueryParams) => {
  let blueprintID: BlueprintEntityID;

  if (isQueryEnabled) {
    blueprintID = new BlueprintEntityID();
    blueprintID.app = appID;
    blueprintID.id = blueprintIDNum;
  } else {
    blueprintID = mockBlueprintID;
  }

  return blueprintID;
};
