import { UserCreateData } from '@playq/octopus2-admin';
import { GenericFailure } from '@playq/services-shared';
import { UserFull } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { createUserMutationKey } from './constants';

export const useUserCreate = (
  options?: UseMutationOptionsExtended<UserFull, GenericFailure | Error, UserCreateData>
) => {
  return useEitherMutation((userCreateData) => services2.adminUsersService.create(userCreateData), {
    mutationKey: createUserMutationKey,
    ...options,
  });
};
