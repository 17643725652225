import { useMemo } from 'react';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter, AppID, Environment } from '@playq/octopus-common';
import { LadderSort } from '@playq/octopus2-social';
import { EnvironmentLaddersResponse } from '@playq/octopus2-tgservices';

import { throwGenericFailure } from '/helpers';
import { services2 } from '/api/services2';
import { useQueryPrefetch, useEitherQuery, UseQueryOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { iteratorMaxLimit } from '/constants';

const emptyArray: [] = [];

const defaultQuery = {
  iterator: new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 }),
  sortBy: [],
  filterBy: {},
};

export const laddersQueryTags: unknown[] = ['ladders', 'service', 'support'];

export const useQueryLadders = (
  appID: AppID | undefined,
  env: Environment,
  iterator: OffsetLimit = defaultQuery.iterator,
  sortBy: LadderSort[] = defaultQuery.sortBy,
  filterBy: { [key: string]: Filter } = defaultQuery.filterBy,
  options?: UseQueryOptionsExtended<EnvironmentLaddersResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(
    () => laddersQueryTags.concat(appID, env, iterator, sortBy, filterBy),
    [appID, env, filterBy, iterator, sortBy]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && appID !== undefined, [options?.enabled, appID]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && appID !== undefined,
    [options?.enablePrefetch, appID]
  );

  const res = useEitherQuery(
    keys,
    () => services2.clerkSupportService.queryLadders(appID as AppID, env, iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => {
        snackbarService.error(`queryLadders-${err.message}`);
        if (err instanceof GenericFailure) {
          throwGenericFailure(err);
        }
      },
      ...options,
      enabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, env, iterator, sortBy, filterBy],
    serviceMethod: services2.clerkSupportService.queryLadders.bind(services2.clerkSupportService),
  });

  return {
    ...res,
    lboards: res.data?.all || emptyArray,
    total,
    tags: keys,
  };
};
