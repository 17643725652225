import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { GroupID, GroupBrief } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { createRequestQueue, unwrapEither } from '/api/service-hooks';

import { groupServiceBriefTags } from './constants';

const queue = createRequestQueue<GroupID, GroupBrief>(
  (groupIDs) => unwrapEither(services2.adminGroupsService.briefFor(groupIDs)),
  {
    predicate: (groupID, groupBrief) => groupID?.serialize() === groupBrief?.id.serialize(),
  }
);

export const useGroupBriefGet = (
  id: GroupID,
  options?: UseQueryOptions<GroupBrief | undefined, GenericFailure | Error>
) =>
  useQuery<GroupBrief | undefined, GenericFailure | Error>(
    groupServiceBriefTags.concat(id?.serialize()),
    () => queue.push(id),
    {
      staleTime: Infinity,
      ...options,
    }
  );
