import { CloudFunctionID } from '@playq/octopus2-cloud';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteCloudFunctionMutationKey } from './constants';

export const useCloudFunctionDelete = (
  options: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, CloudFunctionID>
) => {
  return useEitherMutation((id) => services2.cloudFunctionsService.delete(id), {
    mutationKey: deleteCloudFunctionMutationKey,
    ...options,
  });
};
