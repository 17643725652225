import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Config } from '@playq/octopus2-apps';

import { useScriptsMetadata } from '/common/scripts';

export const useBeforeConfigUpsertScripted = (options?: UseMutationOptions<string, unknown, Config>) => {
  const { invokeScriptsHook } = useScriptsMetadata();
  return useMutation(async (config) => {
    return invokeScriptsHook('onBeforeConfigSave', config) as Promise<string>;
  }, options);
};
