import { AppID } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { AppCloudFunction, CloudFunctionCreateData, CloudFunctionFromTemplateData } from '@playq/octopus2-cloud';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { createCloudFunctionMutationKey } from './constants';

type CloudFunctionCreatePayload =
  | {
      createFromTemplate: true;
      appID: AppID;
      data: CloudFunctionFromTemplateData;
    }
  | {
      createFromTemplate: false;
      appID: AppID;
      data: CloudFunctionCreateData;
    };

export const useCloudFunctionCreate = (
  options: UseMutationOptionsExtended<AppCloudFunction, GenericFailure | Error, CloudFunctionCreatePayload>
) => {
  return useEitherMutation(
    ({ createFromTemplate, appID, data }) =>
      createFromTemplate
        ? services2.cloudFunctionsService.createFromTemplate(appID, data)
        : services2.cloudFunctionsService.create(appID, data),
    { mutationKey: createCloudFunctionMutationKey, ...options }
  );
};
