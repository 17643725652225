import { UseMutationOptions } from '@tanstack/react-query';

import { AccessLevel, PermsScope, PermModule } from '@playq/octopus2-auth';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { PermTarget } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export type UsePermUpsertPayload = [target: PermTarget, scope: PermsScope, module_: PermModule, access: AccessLevel];
export const usePermUpsert = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, UsePermUpsertPayload>
) => {
  return useEitherMutation((args) => services2.adminPermsService.upsertPerm(...args), options);
};
