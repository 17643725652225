import { useCallback } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { SchedulerWebHooks, SchedulerWebHook, SchedulerWebHookId } from '@playq/services-jobot-scheduler';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

const webHooksQueryKeys: unknown[] = ['corp', 'webhooks', 'query'];

export const useWebHooksQuery = (options?: UseQueryOptions<SchedulerWebHooks | undefined, GenericFailure | Error>) => {
  const { mutate, ...res } = useEitherQuery(
    webHooksQueryKeys,
    () => services2.intergrationsWebHookService.retrieveAll(),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const mutateDeletedWebhook = useCallback(
    (id: SchedulerWebHookId) => {
      mutate((prevSchedulerWebHooks) => {
        if (prevSchedulerWebHooks) {
          const nextSchedulerWebHooks = new SchedulerWebHooks();
          nextSchedulerWebHooks.webHooks = prevSchedulerWebHooks.webHooks.filter(
            (h: SchedulerWebHook) => h.id.id !== id.id
          );
          return nextSchedulerWebHooks;
        }
      });
    },
    [mutate]
  );

  return {
    ...res,
    webHooks: res.data instanceof SchedulerWebHooks ? res.data.webHooks : [],
    mutate,
    mutateDeletedWebhook,
  };
};
