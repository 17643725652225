import { Either } from '@playq/irt';
import { GenericFailure } from '@playq/services-shared';
import { CouponTemplateID, CouponID, MassCoupon } from '@playq/services-bookkeeper';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { CouponType } from '/component/Economy/Coupons/types';

import { getCouponMutationKey } from './getCouponMutationKey';
import { createCouponMutationKey } from './constants';
import { CreateCouponPayload } from './types';

export const useCouponCreate = (
  type: CouponType,
  options?: UseMutationOptionsExtended<CouponID | CouponTemplateID, GenericFailure | Error, CreateCouponPayload>
) =>
  useEitherMutation(
    ({ appID, data }) =>
      data instanceof MassCoupon
        ? services2.massCouponsService.create(appID, data.toMassCouponData())
        : (services2.targetedCouponsService.create(appID, data.toCouponTemplateData()) as Promise<
            Either<GenericFailure, CouponID | CouponTemplateID>
          >),
    {
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: getCouponMutationKey(type, createCouponMutationKey),
      ...options,
    }
  );
