import { useCallback } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ExperimentID, AppExperimentResponse, AppExperiment, ExperimentCreateData } from '@playq/octopus2-analytics';

import { useEitherQuery } from '/api/service-hooks';

import { appExperimentsService } from './constants';

export const experimentRetrieveKeys: unknown[] = ['experiment', 'retrieve'];

export const useExperimentRetrieve = (
  id?: ExperimentID,
  version?: number,
  options?: UseQueryOptions<AppExperimentResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    experimentRetrieveKeys.concat(id),
    () => {
      if (id) {
        return appExperimentsService.retrieve(id, version);
      }
    },
    options
  );

  const mutateUpdatedItem = useCallback(
    (updatedData: ExperimentCreateData) => {
      if (res.data) {
        const cloned = new AppExperimentResponse(res.data.serialize());
        cloned.lastVersion += 1;
        cloned.experiment = new AppExperiment(res.data.experiment.serialize());
        cloned.experiment.name = updatedData.name;
        cloned.experiment.endsAtAsString = updatedData.endsAtAsString;
        cloned.experiment.startsAtAsString = updatedData.startsAtAsString;
        cloned.experiment.tags = updatedData.tags;
        cloned.experiment.content = updatedData.content;
        mutate(cloned);
      }
    },
    [mutate, res.data]
  );

  return {
    ...res,
    mutate,
    mutateUpdatedItem,
  };
};
