import { UseMutationOptions } from '@tanstack/react-query';

import { PackagesPolicy } from '@playq/octopus2-economy';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const usePolicyPackagesUpdate = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, PackagesPolicy>
) => {
  return useEitherMutation((policy) => services2.packageAdminService.updatePolicy(policy), options);
};
