import { UseQueryOptions } from '@tanstack/react-query';

import { Company } from '@playq/octopus2-auth';
import { GenericFailure } from '@playq/services-shared';

import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { services2 } from '/api/services2';
import { companyId } from '/constants';
import { throwGenericFailure } from '/helpers';

export const companyTags: unknown[] = ['company', 'service'];

export const useCompanyRetrieve = (
  id = companyId,
  options?: UseQueryOptions<Company | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery<GenericFailure | Error, Company>(
    companyTags.concat(id),
    () => {
      return services2.companyService.retrieve(id);
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        if (err instanceof GenericFailure) {
          throwGenericFailure(err);
        }
        console.error(err);
        snackbarService.genericFailure(err);
      },
      ...options,
    }
  );

  return {
    ...res,
    data: res.data,
  };
};
