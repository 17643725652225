import { GenericFailure } from '@playq/services-shared';
import { Left } from '@playq/irt';
import { GenericFailureCode } from '@playq/services-shared';

import { snackbarService } from '/common/snackbarService';

export const onResponseError = (err: GenericFailure | Error) => snackbarService.genericFailure(err);

/**
 * Accepts a message and failure code and returns a default generic failure.
 * @template T - the type of a successful result.
 */
export const getDefaultGenericFailure = <T = unknown>(message: string, code: GenericFailureCode) =>
  Promise.resolve(
    new Left<GenericFailure, T>(
      new GenericFailure({
        message,
        diagnostics: undefined,
        reserved: {},
        code,
      })
    )
  );
