import { UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';

import { AppBillingResponse } from '@playq/services-taxman';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

export const corpQueryBillingQueryKeys: unknown[] = ['corp', 'query', 'billing', 'query'];

export const useQueryBillingQuery = (
  from: Date,
  till: Date,
  options?: UseQueryOptions<AppBillingResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => corpQueryBillingQueryKeys.concat(from, till), [from, till]);
  return useEitherQuery(keys, () => services2.queryBillingService.query(from, till), {
    keepPreviousData: true,
    ...options,
  });
};
