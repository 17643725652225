import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CheckFilesBatchResponse, CheckFileRequest } from '@playq/octopus2-files';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

const emptyArray: [] = [];

export type UseCheckFilesBatchArgs = { appID?: AppID; batch: CheckFileRequest[] };

export const useCheckFilesBatch = (
  { batch }: UseCheckFilesBatchArgs,
  options?: UseQueryOptions<CheckFilesBatchResponse | undefined, GenericFailure | Error>
) => {
  const keys = ['fileService', 'checkFilesBatch', ...batch.map((f) => f.serialize())];

  const res = useEitherQuery(keys, () => services2.filesService.checkFilesBatch(batch), {
    onError: console.error,
    ...options,
  });

  return {
    ...res,
    checkedFiles: res.data?.batch ?? emptyArray,
  };
};

export type UseCheckFilesBatchType = typeof useCheckFilesBatch;
