import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { UserID } from '@playq/octopus-common';
import { GroupID, Group, GroupsSortField, GroupsSort, QueryGroupsResponse } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { IQuery } from '/common/models';
import { snackbarService } from '/common/snackbarService';

import { corpGroupsQueryKeys } from './constants';

const emptyArray: [] = [];

interface MutateArgs {
  groupID: GroupID;
  userID: UserID;
}

export const useGroupsQuery = (
  query: IQuery<GroupsSortField, GroupsSort>,
  options?: UseQueryOptionsExtended<QueryGroupsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const keys = useMemo(() => corpGroupsQueryKeys.concat(query.iterator, query.sortBy, query.filterBy), [query]);

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.adminGroupsService.queryGroups(query.iterator, query.sortBy, query.filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [query.iterator, query.sortBy, query.filterBy],
    serviceMethod: services2.adminGroupsService.queryGroups.bind(services2.adminGroupsService),
  });

  const mutateDeletedGroup = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator: query.iterator,
    mutate,
    refetch,
    getID: (g?: Group) => g?.id,
    getEntities: (r: QueryGroupsResponse) => r.entities,
    setEntities: (r: QueryGroupsResponse, newEntities: Group[]) => {
      r.entities = newEntities;
    },
    createNewResponse: (prevResponse: QueryGroupsResponse) => new QueryGroupsResponse(prevResponse.serialize()),
  });

  const mutateAddUser = useCallback(
    ({ groupID, userID }: MutateArgs) => {
      mutate((prevQueryGroupsResponse) => {
        if (prevQueryGroupsResponse) {
          const nextResponse = new QueryGroupsResponse();
          const entities = prevQueryGroupsResponse.entities.reduce((acc: Group[], next: Group) => {
            if (next.id === groupID) {
              next.members = next.members.concat(userID);
            }

            acc.push(next);
            return acc;
          }, []);

          nextResponse.entities = entities;
          nextResponse.iterator = prevQueryGroupsResponse.iterator;
          nextResponse.total = prevQueryGroupsResponse.total;

          return nextResponse;
        }
      });
    },
    [mutate]
  );

  const mutateRemoveUser = useCallback(
    ({ groupID, userID }: MutateArgs) => {
      mutate((prevQueryGroupsResponse) => {
        if (prevQueryGroupsResponse) {
          const nextResponse = new QueryGroupsResponse();
          const entities = prevQueryGroupsResponse.entities.reduce((acc: Group[], next: Group) => {
            if (next.id === groupID) {
              next.members = next.members.filter((u) => !(u.company === userID.company && u.id === userID.id));
            }

            acc.push(next);
            return acc;
          }, []);

          nextResponse.entities = entities;
          nextResponse.iterator = prevQueryGroupsResponse.iterator;
          nextResponse.total = prevQueryGroupsResponse.total;

          return prevQueryGroupsResponse;
        }
      });
    },
    [mutate]
  );

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(corpGroupsQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    groups: res.data?.entities ?? emptyArray,
    total,
    tags: keys,
    mutateDeletedGroup,
    mutateAddUser,
    mutateRemoveUser,
    removeQueries,
    refetch,
  };
};
