import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { GameEventID, AppGameEventResponse } from '@playq/octopus2-liveops';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const useGameEventRetrieve = (
  options?: UseMutationOptions<
    AppGameEventResponse,
    GenericFailure | Error,
    { id: GameEventID; version: number | undefined }
  >
) =>
  useEitherMutation(({ id, version }) => services2.appGameEventsService.retrieve(id, version), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
