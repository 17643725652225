import { UseMutationOptions } from '@tanstack/react-query';

import { TaskId } from '@playq/services-jobot-scheduler';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const useIntegrationActivate = (options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, TaskId>) => {
  return useEitherMutation((taskId) => services2.integrationsService.activate(taskId), options);
};
