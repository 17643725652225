import { GroupID, GroupDataStruct } from '@playq/octopus2-admin';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { updateGroupMutationKey } from './constants';

export const useGroupUpdate = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    { groupID: GroupID; data: GroupDataStruct }
  >
) => {
  return useEitherMutation(({ groupID, data }) => services2.adminGroupsService.update(groupID, data), {
    mutationKey: updateGroupMutationKey,
    ...options,
  });
};
