import { UseMutationOptions } from '@tanstack/react-query';

import { SchedulingId, TimeRange } from '@playq/services-jobot-scheduler';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const useIntegrationActivateCustom = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, { id: SchedulingId; range: TimeRange }>
) => {
  return useEitherMutation(({ id, range }) => services2.integrationsService.activateCustom(id, range), options);
};
