import { UserID } from '@playq/octopus-common';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteUserMutationKey } from './constants';

export const useUserDelete = (options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UserID>) => {
  return useEitherMutation((id) => services2.adminUsersService.delete(id), {
    mutationKey: deleteUserMutationKey,
    ...options,
  });
};
