import { UseMutationOptions } from '@tanstack/react-query';

import { PermsScope, PermModule } from '@playq/octopus2-auth';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { PermTarget } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const usePermDelete = (
  options?: UseMutationOptions<
    CommonSuccess,
    GenericFailure | Error,
    [target: PermTarget, scope: PermsScope, module_: PermModule]
  >
) => {
  return useEitherMutation((args) => services2.adminPermsService.deletePerm(...args), options);
};
