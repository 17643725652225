import { UseQueryOptions } from '@tanstack/react-query';

import { TaskId, TaskHistory } from '@playq/services-jobot-scheduler';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

const taskStatusKeys: unknown[] = ['corp', 'webHooks', 'task'];

export const useIntegrationTaskStatus = (
  id?: TaskId,
  options?: UseQueryOptions<TaskHistory | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(taskStatusKeys.concat(id), () => services2.integrationsService.status(id as TaskId), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
    enabled: (options?.enabled ?? true) && id !== undefined,
  });

  return {
    taskHistory: res.data?.serialize(),
    ...res,
  };
};
