import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppEntityContentResponse, AppEntityVersion } from '@playq/octopus2-apps';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { eventsMapperService, eventsMapperServiceName } from './constants';
import { IUpsertEventsParams, IEventsUpsertOptionsVariables } from './types';

export const useEventsMapperUpsertScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptions<
    AppEntityVersion,
    GenericFailure | Error,
    IEventsUpsertOptionsVariables,
    AppEntityContentResponse | undefined
  >
) =>
  useEitherMutationScripted<
    IUpsertEventsParams,
    AppEntityVersion,
    IInvokeScriptsHook,
    AppEntityContentResponse | undefined
  >({
    serviceMethod: eventsMapperService.upsert.bind(eventsMapperService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: eventsMapperServiceName,
      mutationType: 'events save',
    },
    options: {
      onError: onResponseError,
      ...options,
    },
  });
