import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Updater } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import {
  GameEventsSort,
  QueryGameEventsResponse,
  GameEventID,
  GameEventBrief,
  GameEventPromotions,
} from '@playq/octopus2-liveops';
import { Filter, AppID } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';

import { iteratorMaxLimit } from '/constants';
import { services2 } from '/api/services2';
import { IEntityPromotion } from '/api/hooks/promotions';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

import { gameEventsQueryKeys } from './constants';
import { createGEResponseAfterGEDeleting, mutatePromotionsAndFeaturesAfterGEDeleting } from './helpers';

const defaultQuery = {
  iterator: new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 }),
  sortBy: [],
  filterBy: {},
};

const emptyArray: [] = [];

export const useGameEventsQuery = (
  iterator: OffsetLimit = defaultQuery.iterator,
  sortBy: GameEventsSort[] = defaultQuery.sortBy,
  filterBy: {
    [key: string]: Filter;
  } = defaultQuery.filterBy,
  options?: UseQueryOptionsExtended<QueryGameEventsResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const queryClient = useQueryClient();

  const keys = useMemo(
    () => gameEventsQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, filterBy, iterator, sortBy]
  );

  const isValid = useMemo(() => appID !== undefined, [appID]);
  const enabled = useMemo(() => options?.enabled !== false && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => options?.enablePrefetch !== false && isValid,
    [options?.enablePrefetch, isValid]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.appGameEventsService.queryGameEvents(appID as AppID, iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy],
    serviceMethod: services2.appGameEventsService.queryGameEvents.bind(services2.appGameEventsService),
  });

  const mutateUpdatePromotions = useCallback(
    (promotions: Updater<IEntityPromotion<GameEventID>[], IEntityPromotion<GameEventID>[]>) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const response = new QueryGameEventsResponse(prevResponse.serialize());
        response.gameevents = prevResponse.gameevents;
        response.iterator = prevResponse.iterator;
        response.features = prevResponse.features;

        const nextPromtions = typeof promotions === 'function' ? promotions(response.promotions) : promotions;
        response.promotions = nextPromtions.map(
          (p) => new GameEventPromotions({ id: p.id.serialize(), promotions: p.promotions.serialize() })
        );
        return response;
      });
    },
    [mutate]
  );

  const mutateDeletedEvent = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (gE?: GameEventBrief) => gE?.id,
    getEntities: (r: QueryGameEventsResponse) => r.gameevents,
    setEntities: (r: QueryGameEventsResponse, newEntities: GameEventBrief[]) => {
      r.gameevents = newEntities;
    },
    createNewResponse: createGEResponseAfterGEDeleting,
    mutateCustomFields: mutatePromotionsAndFeaturesAfterGEDeleting,
  });

  return {
    ...res,
    events: res.data?.gameevents || emptyArray,
    promotions: (res.data?.promotions || emptyArray) as IEntityPromotion<GameEventID>[],
    total,
    features: res.data?.features || emptyArray,
    tags: keys,
    mutateUpdatePromotions,
    mutateDeletedEvent,
    refetch,
  };
};
