import { useCallback, useMemo } from 'react';
import { UseQueryOptions, useQueryClient } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { FileID, FileResponse, FileFeature, File, FileRevision } from '@playq/octopus2-files';
import { Tag } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { fileGetKeys } from './constants';

export const useFileGet = (
  id?: FileID,
  features: FileFeature[] = [],
  options?: UseQueryOptions<FileResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => fileGetKeys.concat(id), [id]);

  const queryClient = useQueryClient();

  const { mutate, ...res } = useEitherQuery(keys, () => services2.filesService.getFile(id as FileID, features), {
    ...options,
    enabled: (options?.enabled ?? true) && !!id,
  });

  const mutateUpdatedFile = useCallback(
    (file: File, tags?: Tag[]) => {
      if (res.data) {
        const updated = new FileResponse(res.data.serialize());
        updated.file = file;
        if (tags) {
          updated.tags = tags;
        }
        mutate(updated);
      }
    },
    [mutate, res.data]
  );

  const mutateFileRevision = useCallback(
    (revision: FileRevision) => {
      const cached = queryClient.getQueryData<FileResponse>(keys) ?? res.data;
      if (cached) {
        const updated = new FileResponse(cached.serialize());
        const existingRevisionIdx = cached.revisions.findIndex(({ version }) => version === revision.version);
        if (existingRevisionIdx !== -1) {
          updated.revisions = updated.revisions.map((prevRevision, idx) =>
            idx === existingRevisionIdx ? revision : prevRevision
          );
        } else {
          updated.revisions.push(revision);
        }
        mutate(updated);
      }
    },
    [mutate, res.data, queryClient, keys]
  );

  return {
    fileResponse: res.data,
    ...res,
    tags: keys,
    mutate,
    mutateUpdatedFile,
    mutateFileRevision,
  };
};
