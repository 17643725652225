import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { GenericFailure, GenericFailureCode } from '@playq/services-shared';
import { QueryUsersResponse, UsersFilterField, UsersSort } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { UseQueryOptionsExtended, useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { IQuery } from '/common/models';
import { snackbarService } from '/common/snackbarService';

import { corpUsersQueryKeys } from './constants';

const emptyArray: [] = [];

export const useUsersQuery = (
  query: IQuery<UsersFilterField, UsersSort>,
  includeDeleted = false,
  hideAccessError = false,
  options?: UseQueryOptionsExtended<QueryUsersResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const { iterator, sortBy, filterBy } = query;

  const keys = useMemo(
    () => corpUsersQueryKeys.concat(iterator, sortBy, filterBy, includeDeleted),
    [iterator, sortBy, filterBy, includeDeleted]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.adminUsersService.queryUsers(iterator, sortBy, filterBy, includeDeleted),
    {
      keepPreviousData: true,
      onError: (err) => {
        if (hideAccessError && err instanceof GenericFailure && err.code === GenericFailureCode.AccessDenied) {
          return;
        }
        snackbarService.genericFailure(err);
      },
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [iterator, sortBy, filterBy, includeDeleted],
    serviceMethod: services2.adminUsersService.queryUsers.bind(services2.adminUsersService),
  });

  const mutateDeletedUser = useCallback(
    (deletedUserId: number, shouldBeRemoved: boolean) => {
      const nextResponse = nextKeys ? queryClient.getQueryData<QueryUsersResponse>(nextKeys) : undefined;

      mutate((prevResponse?: QueryUsersResponse) => {
        if (!prevResponse) {
          return;
        }
        const updated = new QueryUsersResponse(prevResponse.serialize());
        if (shouldBeRemoved) {
          updated.users = prevResponse.users.filter(({ id: prevUserId }) => prevUserId.id !== deletedUserId);
          updated.total = prevResponse.total - 1;
          if (nextResponse) {
            updated.users.push(nextResponse.users[0]);
          }
        } else {
          updated.users.forEach((user) => {
            if (user.id.id === deletedUserId) {
              user.deleted = !user.deleted;
            }
          });
        }
        return updated;
      });
    },
    [mutate, nextKeys, queryClient]
  );

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(corpUsersQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    users: res.data?.users || emptyArray,
    total,
    tags: keys,
    mutateDeletedUser,
    removeQueries,
    refetch,
  };
};
