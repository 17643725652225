import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { AppFlowResponse } from '@playq/octopus2-liveops';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IUpdateFlowParams, IFlowUpdateOptionsVariables } from './types';
import { flowsService, flowsServiceName, updateFlowMutationKey } from './constants';

export const useFlowUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IFlowUpdateOptionsVariables,
    AppFlowResponse | undefined
  >
) =>
  useEitherMutationScripted<IUpdateFlowParams, CommonSuccess, IInvokeScriptsHook, AppFlowResponse | undefined>({
    serviceMethod: flowsService.update.bind(flowsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: flowsServiceName,
      mutationType: 'flow update',
    },
    options: {
      onError: onResponseError,
      mutationKey: updateFlowMutationKey,
      ...options,
    },
  });
