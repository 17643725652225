import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { BillingConfiguration } from '@playq/services-taxman';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const useAdminBillingConfigure = (
  options?: UseMutationOptions<BillingConfiguration, GenericFailure | Error, BillingConfiguration>
) => {
  return useEitherMutation((config) => services2.adminBillingService.configure(config), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
};
