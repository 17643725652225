import { QueryGameEventsResponse, GameEventID } from '@playq/octopus2-liveops';

import { MutateCustomFieldsArgs } from '/api/service-hooks';
import { filterById } from '/helpers';

export const mutatePromotionsAndFeaturesAfterGEDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<QueryGameEventsResponse, GameEventID>) => {
  if (!nextResponse || !id) {
    return;
  }
  const eventPromotions = nextResponse.promotions.find(filterById(id, true));
  if (eventPromotions !== undefined) {
    response.promotions.push(eventPromotions);
  }
  const eventFeatures = nextResponse.features.find(filterById(id, true));
  if (eventFeatures !== undefined) {
    response.features.push(eventFeatures);
  }
};

export const createGEResponseAfterGEDeleting = (
  prevResponse: QueryGameEventsResponse,
  removedEntityID?: GameEventID | GameEventID[]
) => {
  const newResponse = new QueryGameEventsResponse(prevResponse.serialize());
  if (removedEntityID !== undefined) {
    newResponse.features = prevResponse.features.filter(filterById(removedEntityID));
  }
  return newResponse;
};
