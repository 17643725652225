import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CouponTemplateGetResponse, MassCouponGetResponse } from '@playq/octopus2-economy';
import { CouponID, CouponTemplateID, MassCoupon } from '@playq/services-bookkeeper';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { CouponType } from '/component/Economy/Coupons/types';

import { UpdateCouponPayload } from './useCouponUpdate';
import { couponGetKeys } from './constants';

export const useCouponGet = (
  id?: CouponID | CouponTemplateID,
  options?: UseQueryOptions<MassCouponGetResponse | CouponTemplateGetResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(
    () => couponGetKeys[id instanceof CouponID ? CouponType.mass : CouponType.targeted].concat(id),
    [id]
  );
  const { mutate, ...res } = useEitherQuery(
    keys,
    () => {
      if (id) {
        if (id instanceof CouponID) {
          return services2.massCouponsService.get(id);
        }
        return services2.targetedCouponsService.get(id);
      }
    },
    options
  );

  const mutateUpdatedCoupon = useCallback(
    (payload: UpdateCouponPayload) => {
      if (res.data) {
        if (payload instanceof MassCoupon) {
          const couponResponse = new MassCouponGetResponse((res.data as MassCouponGetResponse).serialize());
          couponResponse.coupon = payload;
          couponResponse.itemDetails = res.data.itemDetails;

          mutate(couponResponse);
        }
      }
    },
    [mutate, res.data]
  );
  const isLoading = res.isLoading && res.fetchStatus !== 'idle';
  return {
    ...res,
    isLoading,
    tags: keys,
    mutate,
    mutateUpdatedCoupon,
  };
};
