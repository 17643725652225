import { UploadBatchResponse } from '@playq/octopus2-files';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { onResponseError } from '../helpers';
import { useEitherMutationScripted } from '../scriptsService';

import { IUploadFilesBatchParams, UseFilesUploadBatchOptions } from './types';
import { filesService, filesServiceName, uploadBatchFilesMutationKey, FILES_BATCH_UPLOAD_HOOK_NAME } from './constants';

export const useFilesUploadBatchScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseFilesUploadBatchOptions
) =>
  useEitherMutationScripted<IUploadFilesBatchParams, UploadBatchResponse, IInvokeScriptsHook>({
    serviceMethod: filesService.uploadBatch.bind(filesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: filesServiceName,
      mutationType: FILES_BATCH_UPLOAD_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: uploadBatchFilesMutationKey,
      ...options,
    },
  });
