import { QueryFlowsResponse } from '@playq/octopus2-liveops';
import { FlowID } from '@playq/octopus-common';

import { MutateCustomFieldsArgs } from '/api/service-hooks';
import { filterById } from '/helpers';

export const createFlowsResponseAfterFlowDeleting = (
  prevResponse: QueryFlowsResponse,
  removedFlowID?: FlowID | FlowID[]
) => {
  const newResponse = new QueryFlowsResponse(prevResponse.serialize());
  if (removedFlowID !== undefined) {
    newResponse.features = prevResponse.features.filter(filterById(removedFlowID));
  }
  return newResponse;
};

export const mutatePromotionsAndFeaturesAfterFlowDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<QueryFlowsResponse, FlowID>) => {
  if (!id || !nextResponse) {
    return;
  }
  const nextFlowPromotions = nextResponse.promotions.find(filterById(id, true));
  if (nextFlowPromotions !== undefined) {
    response.promotions.push(nextFlowPromotions);
  }
  const nextFlowFeatures = nextResponse.features.find(filterById(id, true));
  if (nextFlowFeatures !== undefined) {
    response.features.push(nextFlowFeatures);
  }
};
