import { useMemo } from 'react';

import { Environment } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  AppLadderId,
  LadderInstanceDimensions,
  TeamLadderInstanceDimensions,
  TeamLadderScores,
} from '@playq/services-clerk';

import { throwGenericFailure } from '/helpers';
import { services2 } from '/api/services2';
import { useEitherQuery, UseQueryOptionsExtended, useQueryPrefetch } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { laddersQueryTags } from './useQueryLadders';

export const queryTeamsLadderScoresKeys = laddersQueryTags.concat('queryTeamsLadderScores');

export const useQueryTeamsLadderScores = (
  env: Environment,
  id: AppLadderId | undefined,
  aggregatedLadderDimensions: LadderInstanceDimensions | TeamLadderInstanceDimensions | undefined,
  iterator: OffsetLimit,
  maybeBucketId: number | undefined,
  options?: UseQueryOptionsExtended<TeamLadderScores | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(
    () => queryTeamsLadderScoresKeys.concat(env, id, aggregatedLadderDimensions, iterator, maybeBucketId),
    [aggregatedLadderDimensions, env, id, iterator, maybeBucketId]
  );

  const isValid = useMemo(
    () => id !== undefined && aggregatedLadderDimensions instanceof LadderInstanceDimensions,
    [aggregatedLadderDimensions, id]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && isValid,
    [options?.enablePrefetch, isValid]
  );

  const res = useEitherQuery(
    keys,
    () =>
      services2.teamScoreSupportService.queryTeamLadderInstanceScores(
        env,
        id as AppLadderId,
        aggregatedLadderDimensions as LadderInstanceDimensions,
        iterator,
        maybeBucketId
      ),
    {
      onError: (err) => {
        snackbarService.error(`queryLadders-${err.message}`);
        if (err instanceof GenericFailure) {
          throwGenericFailure(err);
        }
      },
      ...options,
      enabled,
    }
  );

  const scores = useMemo(() => res.data?.scores, [res.data?.scores]);

  useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    hasMore: scores !== undefined && scores.length === iterator.limit,
    args: [env, id as AppLadderId, aggregatedLadderDimensions as LadderInstanceDimensions, iterator, maybeBucketId],

    serviceMethod: services2.teamScoreSupportService.queryTeamLadderInstanceScores.bind(
      services2.teamScoreSupportService
    ),
  });

  // default value is not provided for checks if request was made
  return { ...res, scores: res.data?.scores, maybeBucket: res.data?.maybeBucket, queryKeys: keys };
};
