import { UpdateIntegrationData } from '@playq/services-adhawk-scheduler';
import { SchedulingId, Scheduling } from '@playq/services-jobot-scheduler';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { updateIntegrationMutationKey } from './constants';

export const useIntegrationUpdate = (
  options?: UseMutationOptionsExtended<
    Scheduling,
    GenericFailure | Error,
    { id: SchedulingId; data: UpdateIntegrationData }
  >
) => {
  return useEitherMutation(({ id, data }) => services2.integrationsService.update(id, data), {
    mutationKey: updateIntegrationMutationKey,
    ...options,
  });
};
