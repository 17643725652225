import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

import { uploadRetryFileMutationKey } from './constants';
import { UseFileUploadRetryMutationType } from './types';

export const useFileUploadRetry: UseFileUploadRetryMutationType = (options) =>
  useEitherMutation(({ id, mime, sha256 }) => services2.filesService.uploadRetry(id, mime, sha256), {
    mutationKey: uploadRetryFileMutationKey,
    ...options,
  });
