import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Updater } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { AppID, Filter } from '@playq/octopus-common';
import {
  QueryCloudFunctionsResponse,
  CloudFunctionPromotions,
  CloudFunctionsSort,
  CloudFunctionID,
  CloudFunctionBrief,
} from '@playq/octopus2-cloud';

import { services2 } from '/api/services2';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
  MutateCustomFieldsArgs,
} from '/api/service-hooks';
import { IEntityPromotion } from '/api/hooks/promotions';

import { cloudFunctionsQueryKeys } from './constants';

const emptyArray: [] = [];

const mutatePromotionsAfterCFDeleting = ({
  response,
  id,
  nextResponse,
}: MutateCustomFieldsArgs<QueryCloudFunctionsResponse, CloudFunctionID>) => {
  if (!id || !nextResponse) {
    return;
  }
  const nextCFPromotions = nextResponse.promotions.find(
    ({ id: promotedCFID }) => promotedCFID.serialize() === id.serialize()
  );
  if (nextCFPromotions !== undefined) {
    response.promotions.push(nextCFPromotions);
  }
};

export const useCloudFunctionsQuery = (
  appID: AppID | undefined,
  iterator: OffsetLimit,
  sortBy: CloudFunctionsSort[],
  filterBy: {
    [key: string]: Filter;
  },
  options?: UseQueryOptionsExtended<QueryCloudFunctionsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const keys = useMemo(
    () => cloudFunctionsQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, filterBy, iterator, sortBy]
  );

  const isValid = useMemo(() => appID !== undefined, [appID]);
  const enabled = useMemo(() => (options?.enabled ?? true) && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && isValid,
    [options?.enablePrefetch, isValid]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.cloudFunctionsService.queryCloudFunctions(appID as AppID, iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      ...options,
      enabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy],
    serviceMethod: services2.cloudFunctionsService.queryCloudFunctions.bind(services2.cloudFunctionsService),
  });

  const mutateUpdatePromotions = useCallback(
    (promotions: Updater<IEntityPromotion<CloudFunctionID>[], IEntityPromotion<CloudFunctionID>[]>) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const response = new QueryCloudFunctionsResponse(prevResponse.serialize());
        response.cloudFunctions = prevResponse.cloudFunctions;
        response.iterator = prevResponse.iterator;
        response.features = prevResponse.features;

        const nextPromtions = typeof promotions === 'function' ? promotions(response.promotions) : promotions;
        response.promotions = nextPromtions.map(
          (p) => new CloudFunctionPromotions({ id: p.id.serialize(), promotions: p.promotions.serialize() })
        );
        return response;
      });
    },
    [mutate]
  );

  const mutateDeletedItem = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (cF?: CloudFunctionBrief) => cF?.id,
    getEntities: (r: QueryCloudFunctionsResponse) => r.cloudFunctions,
    setEntities: (r: QueryCloudFunctionsResponse, newEntities: CloudFunctionBrief[]) => {
      r.cloudFunctions = newEntities;
    },
    createNewResponse: (prevResponse: QueryCloudFunctionsResponse) =>
      new QueryCloudFunctionsResponse(prevResponse.serialize()),
    mutateCustomFields: mutatePromotionsAfterCFDeleting,
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(cloudFunctionsQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    cloudFunctions: res.data?.cloudFunctions || emptyArray,
    promotions: (res.data?.promotions || emptyArray) as IEntityPromotion<CloudFunctionID>[],
    total,
    tags: keys,
    mutateUpdatePromotions,
    mutateDeletedItem,
    removeQueries,
    refetch,
  };
};
