import { CreateIntegrationData } from '@playq/services-adhawk-scheduler';
import { Scheduling } from '@playq/services-jobot-scheduler';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { setupIntegrationMutationKey } from './constants';

export const useIntegrationSetup = (
  options?: UseMutationOptionsExtended<Scheduling, GenericFailure | Error, CreateIntegrationData>
) => {
  return useEitherMutation((createData) => services2.integrationsService.setup(createData), {
    mutationKey: setupIntegrationMutationKey,
    ...options,
  });
};
