import { useMemo } from 'react';
import { UseQueryOptions, useQueryClient } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { AppLadderInstanceId, AppTeamLadderInstanceId, LadderScores } from '@playq/services-clerk';

import { throwGenericFailure } from '/helpers';
import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { laddersQueryTags } from './useQueryLadders';

export const queryLadderInstanceScoresKeys: unknown[] = laddersQueryTags.concat(['queryLadderInstanceScores']);

export const useQueryLadderInstanceScores = (
  env: Environment,
  instanceId: AppLadderInstanceId | AppTeamLadderInstanceId | undefined,
  iterator: OffsetLimit,
  maybeBucketId: number | undefined,
  options?: UseQueryOptions<LadderScores | undefined, Error | GenericFailure>
) => {
  const keys = useMemo(
    () => queryLadderInstanceScoresKeys.concat(env, instanceId, iterator, maybeBucketId),
    [env, instanceId, iterator, maybeBucketId]
  );

  const enabled = useMemo(() => options?.enabled !== false && !!instanceId, [options?.enabled, instanceId]);

  const queryClient = useQueryClient();

  const cachedData = queryClient.getQueryData<LadderScores>(keys);

  const { mutate, ...res } = useEitherQuery(
    keys,
    () => {
      if (instanceId instanceof AppLadderInstanceId) {
        return services2.userScoreSupportService.queryLadderInstanceScores(env, instanceId, iterator, maybeBucketId);
      }
      if (instanceId instanceof AppTeamLadderInstanceId) {
        return services2.teamScoreSupportService.queryLadderInstanceScores(env, instanceId, iterator, maybeBucketId);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        snackbarService.error(`queryLadderInstanceScores-${err.message}`);
        if (err instanceof GenericFailure) {
          throwGenericFailure(err);
        }
      },
      ...options,
      enabled,
    }
  );

  const data = !instanceId ? undefined : res.data ?? cachedData;

  return {
    ...res,
    // default value is not provided for checks if request was made
    scores: data?.scores,
    maybeBucket: data?.maybeBucket,
    queryKeys: keys,
  };
};
