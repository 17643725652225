import { FileRevisionID } from '@playq/octopus2-files';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type UseDeleteRevisionArgs = { appID?: AppID; id: FileRevisionID };

export const useDeleteFileRevision = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UseDeleteRevisionArgs>
) => {
  return useEitherMutation(({ id }) => services2.filesService.deleteRevision(id), {
    onError: () => {
      snackbarService.error('Could not delete the revision');
    },
    onSuccess: () => {
      snackbarService.success('Revision deleted successfully');
    },
    ...options,
  });
};

export type UseDeleteFileRevisionType = typeof useDeleteFileRevision;
