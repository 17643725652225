import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { UserFull } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { updateUserMutationKey } from './constants';

export const useUserUpdate = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    { user: UserFull; pass: string | undefined }
  >
) => {
  return useEitherMutation(({ user, pass }) => services2.adminUsersService.update(user, pass), {
    mutationKey: updateUserMutationKey,
    ...options,
  });
};
