import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { FlowID } from '@playq/octopus-common';
import { AppFlowResponse } from '@playq/octopus2-liveops';

import { useEitherQuery } from '/api/service-hooks';
import { services2 } from '/api/services2';

export const flowRetrieveKeys: unknown[] = ['flow', 'retrieve'];

export const useFlowRetrieve = (
  id?: FlowID,
  version?: number,
  options?: UseQueryOptions<AppFlowResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    flowRetrieveKeys.concat(id),
    () => {
      if (id) {
        return services2.flowsService.retrieve(id, version);
      }
    },
    options
  );

  return res;
};
