import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { FileResponse } from '@playq/octopus2-files';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IFileUpdateRevisionOptionsVariables, IUpdateRevisionParams } from './types';
import { filesService, filesServiceName, updateFileRevisionMutationKey, FILE_UPLOAD_HOOK_NAME } from './constants';

export const useFileUpdateRevisionScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IFileUpdateRevisionOptionsVariables,
    FileResponse
  >
) =>
  useEitherMutationScripted<IUpdateRevisionParams, CommonSuccess, IInvokeScriptsHook, FileResponse>({
    serviceMethod: filesService.updateRevision.bind(filesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: filesServiceName,
      mutationType: FILE_UPLOAD_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: updateFileRevisionMutationKey,
      ...options,
    },
  });
