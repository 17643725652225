import { BlueprintEntityID } from '@playq/octopus2-analytics';

import { Services2, services2 } from '/api';

export const { blueprintsService }: Services2 = services2;
export const blueprintsServiceName: string = blueprintsService.getClassName();

export const mockBlueprintID = (() => {
  const id = new BlueprintEntityID();

  id.app = 0;
  id.id = 0;

  return id;
})();
