import { WebEventData, WebEvent } from '@playq/octopus2-admin';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { createWebEventMutationKey } from './constants';

export const useWebEventCreate = (
  options?: UseMutationOptionsExtended<WebEvent, GenericFailure | Error, WebEventData>
) => {
  return useEitherMutation((eventData) => services2.webEventsService.create(eventData), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: createWebEventMutationKey,
    ...options,
  });
};
