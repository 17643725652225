import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { UnconfiguredMetrics } from '@playq/services-taxman';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

export const corpBillingUnconfiguredQueryKeys: unknown[] = ['corp', 'admin', 'billing', 'unconfigured', 'query'];

export const useAdminBillingUnconfiguredQuery = (
  options?: UseQueryOptions<UnconfiguredMetrics | undefined, GenericFailure | Error>
) => {
  return useEitherQuery(corpBillingUnconfiguredQueryKeys, () => services2.adminBillingService.getUnconfigured(), {
    keepPreviousData: true,
    ...options,
  });
};
