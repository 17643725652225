import { GenericFailure } from '@playq/services-shared';
import { FileResponse, UploadSingleResponse } from '@playq/octopus2-files';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IFileUploadOptionsVariables, IUploadFileParams } from './types';
import { FILE_UPLOAD_HOOK_NAME, filesService, filesServiceName, uploadFileMutationKey } from './constants';

export const useFileUploadScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    UploadSingleResponse,
    GenericFailure | Error,
    IFileUploadOptionsVariables,
    FileResponse
  >
) =>
  useEitherMutationScripted<IUploadFileParams, UploadSingleResponse, IInvokeScriptsHook, FileResponse>({
    serviceMethod: filesService.uploadFileEntity.bind(filesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: filesServiceName,
      mutationType: FILE_UPLOAD_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: uploadFileMutationKey,
      ...options,
    },
  });
