import { GroupData, Group } from '@playq/octopus2-admin';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { createGroupMutationKey } from './constants';

export const useGroupCreate = (options?: UseMutationOptionsExtended<Group, GenericFailure | Error, GroupData>) => {
  return useEitherMutation((groupData) => services2.adminGroupsService.create(groupData), {
    mutationKey: createGroupMutationKey,
    ...options,
  });
};
