import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CreativeAssetsPolicy } from '@playq/octopus2-files';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

const policyAssetsQueryKeys: unknown[] = ['corp', 'policies', 'assets', 'query'];

export const usePolicyAssetsQuery = (
  options?: UseQueryOptions<CreativeAssetsPolicy | undefined, GenericFailure | Error>
) => {
  return useEitherQuery(policyAssetsQueryKeys, () => services2.creativeAssetsAdminService.retrievePolicy(), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
};
