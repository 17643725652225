import { QueryKey, MutationKey } from '@tanstack/react-query';

import { services2 } from '/api/services2';

export const fileGetKeys: QueryKey = ['file', 'get'];
export const filesQueryKeys: QueryKey = ['files', 'query'];
export const checkFileKeys: QueryKey = ['file', 'checkFile'];

export const filesService = services2.filesService;
export const filesServiceName = filesService.getClassName();

export const createFileMutationKey: MutationKey = ['file', 'mutation', 'create'];
export const updateFileMutationKey: MutationKey = ['file', 'mutation', 'update'];
export const updateFileRevisionMutationKey: MutationKey = ['file', 'mutation', 'updateRevision'];
export const removeFileMutationKey: MutationKey = ['file', 'mutation', 'remove'];
export const removeFilesMutationKey: MutationKey = ['files', 'mutation', 'remove'];
export const uploadFileMutationKey: MutationKey = ['file', 'mutation', 'uploadFileEntity'];
export const uploadRetryFileMutationKey: MutationKey = ['file', 'mutation', 'uploadRetry'];
export const uploadBatchFilesMutationKey: MutationKey = ['file', 'mutation', 'uploadBatch'];

// the same names as the names of the methods in /common/scripts/definitions/script-hooks.d.ts
export const FILE_UPLOAD_HOOK_NAME = 'onFileUpload';
export const FILE_SAVE_HOOK_NAME = 'onFileSave';
export const FILES_BATCH_UPLOAD_HOOK_NAME = 'onFilesBatchUpload';
