import { GenericFailure } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';
import { CopyEntityID, CopyEntityCreateData } from '@playq/octopus2-creatives';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { createCopyMutationKey } from './constants';

export type CopyCreatePayload = {
  appID: AppID;
  payload: CopyEntityCreateData;
};

export const useCopyCreate = (
  options?: UseMutationOptionsExtended<CopyEntityID, GenericFailure | Error, CopyCreatePayload>
) =>
  useEitherMutation(({ appID, payload }) => services2.copiesService.create(appID, payload), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: createCopyMutationKey,
    ...options,
  });
