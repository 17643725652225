import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Updater } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { AppID, Filter } from '@playq/octopus-common';
import {
  ExperimentID,
  ExperimentPromotions,
  ExperimentsSort,
  QueryExperimentsResponse,
  ExperimentBrief,
} from '@playq/octopus2-analytics';

import { IEntityPromotion } from '/api/hooks/promotions';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';

import { appExperimentsService, experimentsQueryKeys } from './constants';
import {
  createExperimentsResponseAfterExperimentDeleting,
  mutatePromotionsAndFeaturesAfterExperimentDeleting,
} from './helpers';

const emptyArray: [] = [];
const emptyFilterBy: {
  [key: string]: Filter;
} = {};

export const useExperimentsQuery = (
  appID: AppID | undefined,
  iterator: OffsetLimit,
  sortBy: ExperimentsSort[] = emptyArray,
  filterBy: {
    [key: string]: Filter;
  } = emptyFilterBy,
  options?: UseQueryOptionsExtended<QueryExperimentsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const keys = useMemo(
    () => experimentsQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, filterBy, iterator, sortBy]
  );

  const isValid = useMemo(() => appID !== undefined, [appID]);
  const enabled = useMemo(() => options?.enabled !== false && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => options?.enablePrefetch !== false && isValid,
    [options?.enablePrefetch, isValid]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => appExperimentsService.queryExperiments(appID as AppID, iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      ...options,
      enabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy],
    serviceMethod: appExperimentsService.queryExperiments.bind(appExperimentsService),
  });

  const mutateUpdatePromotions = useCallback(
    (promotions: Updater<IEntityPromotion<ExperimentID>[], IEntityPromotion<ExperimentID>[]>) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const response = new QueryExperimentsResponse(prevResponse.serialize());
        response.experiments = prevResponse.experiments;
        response.iterator = prevResponse.iterator;
        response.features = prevResponse.features;

        const nextPromtions = typeof promotions === 'function' ? promotions(response.promotions) : promotions;
        response.promotions = nextPromtions.map(
          (p) => new ExperimentPromotions({ id: p.id.serialize(), promotions: p.promotions.serialize() })
        );
        return response;
      });
    },
    [mutate]
  );

  const mutateDeletedExperiment = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (e?: ExperimentBrief) => e?.id,
    getEntities: (r: QueryExperimentsResponse) => r.experiments,
    setEntities: (r: QueryExperimentsResponse, newEntities: ExperimentBrief[]) => {
      r.experiments = newEntities;
    },
    createNewResponse: createExperimentsResponseAfterExperimentDeleting,
    mutateCustomFields: mutatePromotionsAndFeaturesAfterExperimentDeleting,
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(experimentsQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    experiments: res.data?.experiments || emptyArray,
    promotions: (res.data?.promotions || emptyArray) as IEntityPromotion<ExperimentID>[],
    total,
    tags: keys,
    refetch,
    mutateUpdatePromotions,
    mutateDeletedExperiment,
    removeQueries,
  };
};
