import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { Tag } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const useTransformTag = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, { tags: Tag[]; toTag: Tag }>
) => {
  return useEitherMutation(({ tags, toTag }) => services2.tagsService.transform(tags, toTag), options);
};
