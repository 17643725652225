import { ExperimentsServiceClient } from '@playq/octopus2-analytics';

import { services2 } from '/api/services2';

export const appExperimentsService: ExperimentsServiceClient = services2.appExperimentsService;
export const appExperimentsServiceName: string = appExperimentsService.getClassName();

export const experimentsQueryKeys: unknown[] = ['experiments', 'query'];

export const createExperimentMutationKeys: unknown[] = ['experiment', 'create'];
export const updateExperimentMutationKeys: unknown[] = ['experiment', 'update'];
export const removeExperimentMutationKeys: unknown[] = ['experiment', 'remove'];
