import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { SchedulingId, Scheduling } from '@playq/services-jobot-scheduler';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { integrationGetKeys } from './constants';

export const useIntegrationGet = (
  id?: SchedulingId,
  options?: UseQueryOptions<Scheduling | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(
    integrationGetKeys.concat(id),
    () => services2.integrationsService.retrieve(id as SchedulingId),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled: (options?.enabled ?? true) && id !== undefined,
    }
  );

  return {
    integration: res.data,
    ...res,
  };
};
