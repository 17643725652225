import { GenericFailure, UserId, CommonSuccess } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { AppLadderInstanceId, AppTeamLadderInstanceId } from '@playq/services-clerk';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { queryLadderInstanceScoresKeys } from './useQueryLadderInstanceScores';

export type DeleteScorePayloadType = {
  env: Environment;
  instance: AppLadderInstanceId | AppTeamLadderInstanceId;
  userId: UserId;
};

export const useDeleteScore = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, DeleteScorePayloadType>
) =>
  useEitherMutation(
    ({ env, instance, userId }) => {
      if (instance instanceof AppTeamLadderInstanceId) {
        return services2.teamScoreSupportService.removeScore(env, instance, userId);
      }
      return services2.userScoreSupportService.removeScore(env, instance, userId);
    },
    {
      removeQueriesKeys: queryLadderInstanceScoresKeys,
      ...options,
    }
  );
