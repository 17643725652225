import { UseMutationOptions } from '@tanstack/react-query';

import { BlueprintEntityID } from '@playq/octopus2-analytics';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2, useEitherMutation } from '/api';
import { onResponseError } from '/api/hooks/helpers';

export const useBlueprintDelete = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, BlueprintEntityID>
) =>
  useEitherMutation(
    (blueprintID: BlueprintEntityID) => {
      return services2.blueprintsService.delete(blueprintID);
    },
    {
      ...options,
      onError: onResponseError,
    }
  );
