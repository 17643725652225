import { GroupID } from '@playq/octopus2-admin';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteGroupMutationKey } from './constants';

export const useGroupDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, GroupID>
) => {
  return useEitherMutation((id) => services2.adminGroupsService.delete(id), {
    mutationKey: deleteGroupMutationKey,
    ...options,
  });
};
