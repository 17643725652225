import { useMemo } from 'react';

import { TimeRange } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { QueryRecordsResponse, LogsSort, LogsSortField } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { UseQueryOptionsExtended, useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { IQuery } from '/common/models';

const logsQueryKeys: unknown[] = ['corp', 'logs', 'query'];

export const useLogsQuery = (
  range: TimeRange,
  query: IQuery<LogsSortField, LogsSort>,
  options?: UseQueryOptionsExtended<QueryRecordsResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(
    () => logsQueryKeys.concat(range, query.iterator, query.sortBy, query.filterBy),
    [range, query.iterator, query.sortBy, query.filterBy]
  );

  const res = useEitherQuery(
    keys,
    () => services2.logsService.queryRecords(range, query.iterator, query.sortBy, query.filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [range, query.iterator, query.sortBy, query.filterBy],
    serviceMethod: services2.logsService.queryRecords.bind(services2.logsService),
  });

  return {
    records: res?.data?.entities ?? [],
    total,
    ...res,
    tags: keys,
  };
};
