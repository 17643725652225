import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { ExperimentID } from '@playq/octopus2-analytics';

import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { appExperimentsService, removeExperimentMutationKeys } from './constants';

export const useExperimentDelete = (
  options: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, ExperimentID>
) => {
  return useEitherMutation((id) => appExperimentsService.delete(id), {
    mutationKey: removeExperimentMutationKeys,
    ...options,
  });
};
