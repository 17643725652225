import { WebEventID } from '@playq/octopus2-admin';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteWebEventMutationKey } from './constants';

export const useWebEventDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, WebEventID>
) => {
  return useEitherMutation((id) => services2.webEventsService.delete(id), {
    mutationKey: deleteWebEventMutationKey,
    ...options,
  });
};
