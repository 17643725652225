import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { GenericFailure } from '@playq/services-shared';
import {
  IntegrationsFilter,
  IntegrationsSort,
  IntegrationSortField,
  IntegrationFilterField,
  QueryIntegrationsResponse,
} from '@playq/services-adhawk-scheduler';
import { SchedulingBrief } from '@playq/services-jobot-scheduler';

import { services2 } from '/api/services2';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { IQuery } from '/common/models';
import { snackbarService } from '/common/snackbarService';

import { corpIntegrationsQueryKeys } from './constants';

const emptyArray: [] = [];
const emptyObject: Record<string, unknown> = {};

export const useIntegrationsQuery = (
  query: IQuery<IntegrationSortField, IntegrationsSort>,
  options?: UseQueryOptionsExtended<QueryIntegrationsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const filterBy: IntegrationsFilter | undefined = useMemo(() => {
    if (_.isEmpty(query.filterBy)) {
      return undefined;
    }

    const integrationFilter = new IntegrationsFilter();
    const fb = query.filterBy;
    integrationFilter.field = Object.keys(fb)[0] as IntegrationFilterField;
    integrationFilter.filter = fb[integrationFilter.field];

    return integrationFilter;
  }, [query.filterBy]);

  const sortBy: IntegrationsSort = useMemo(() => query.sortBy[0], [query.sortBy]);

  const iterator = query.iterator;

  const keys = useMemo(
    () => corpIntegrationsQueryKeys.concat(iterator, sortBy, filterBy),
    [filterBy, sortBy, iterator]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.integrationsService.query(query.iterator, filterBy, sortBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [query.iterator, filterBy, sortBy],
    serviceMethod: services2.integrationsService.query.bind(services2.integrationsService),
  });

  const mutateDeletedIntegration = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (s?: SchedulingBrief) => s?.id,
    getEntities: (r: QueryIntegrationsResponse) => r.scheduled,
    setEntities: (r: QueryIntegrationsResponse, newEntities: SchedulingBrief[]) => {
      r.scheduled = newEntities;
    },
    createNewResponse: (prevResponse: QueryIntegrationsResponse) =>
      new QueryIntegrationsResponse(prevResponse.serialize()),
    mutateCustomFields: ({ response, nextResponse }) => {
      response.details = { ...response.details, ...nextResponse?.details };
    },
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(corpIntegrationsQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    scheduled: res.data?.scheduled ?? emptyArray,
    details: res.data?.details ?? emptyObject,
    total,
    tags: keys,
    mutateDeletedIntegration,
    removeQueries,
    refetch,
  };
};
