import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { AppGameEventResponse } from '@playq/octopus2-liveops';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IUpdateGameEventParams, IGameEventUpdateOptionsVariables } from './types';
import { gameEventsServiceName, gameEventsService, updateGameEventMutationKey } from './constants';

export const useGameEventUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IGameEventUpdateOptionsVariables,
    AppGameEventResponse | undefined
  >
) =>
  useEitherMutationScripted<
    IUpdateGameEventParams,
    CommonSuccess,
    IInvokeScriptsHook,
    AppGameEventResponse | undefined
  >({
    serviceMethod: gameEventsService.update.bind(gameEventsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: gameEventsServiceName,
      mutationType: 'game event update',
    },
    options: {
      mutationKey: updateGameEventMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
