import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

import { removeFileMutationKey } from './constants';
import { UseFileDeleteOptions } from './types';

export const useFileDelete = (options?: UseFileDeleteOptions) => {
  return useEitherMutation(({ fileID }) => services2.filesService.delete(fileID), {
    mutationKey: removeFileMutationKey,
    ...options,
  });
};
