import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import {
  LadderTemplate,
  LadderDimensionsResponse,
  TeamLadderDimensionsResponse,
  TeamBucket,
  AppLadderId,
} from '@playq/services-clerk';

import { throwGenericFailure } from '/helpers';
import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { laddersQueryTags } from './useQueryLadders';

const emptyArray: [] = [];

export const queryLadderInstanseDimensionsKeys: unknown[] = laddersQueryTags.concat(['queryLadderInstanceDimensions']);

export const useQueryLadderInstanceDimensions = (
  env: Environment,
  ladder: LadderTemplate | undefined,
  iterator: OffsetLimit,
  options?: UseQueryOptions<LadderDimensionsResponse | TeamLadderDimensionsResponse | undefined, Error | GenericFailure>
) => {
  const id = ladder?.id;

  const keys = useMemo(() => queryLadderInstanseDimensionsKeys.concat(env, id, iterator), [env, id, iterator]);

  const enabled = useMemo(
    () => (options?.enabled ?? true) && id?.appId !== undefined && id?.ladderId !== undefined,
    [options?.enabled, id]
  );

  const res = useEitherQuery(
    keys,
    () => {
      const ladderType = ladder?.ladderType;
      if (ladderType instanceof TeamBucket) {
        return services2.teamScoreSupportService.queryLadderInstanceDimensions(env, id as AppLadderId, iterator);
      }
      return services2.userScoreSupportService.queryLadderInstanceDimensions(env, id as AppLadderId, iterator);
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        snackbarService.error(`queryLadders-${err.message}`);
        if (err instanceof GenericFailure) {
          throwGenericFailure(err);
        }
      },
      ...options,
      enabled,
    }
  );

  const dimensions = useMemo(() => {
    if (res?.data === undefined) {
      return undefined;
    }
    return res.data.dimensions;
  }, [res?.data]);

  return { ...res, tags: keys, dimensions: dimensions || emptyArray };
};
