import { UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';

import { AppID } from '@playq/octopus-common';
import { AppBillingResponse } from '@playq/services-taxman';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

export const corpQueryBillingByAppQueryKeys: unknown[] = ['corp', 'query', 'billing', 'by app', 'query'];

export const useQueryBillingByAppQuery = (
  from: Date,
  till: Date,
  appID?: AppID,
  options?: UseQueryOptions<AppBillingResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => corpQueryBillingByAppQueryKeys.concat(from, till, appID), [from, till, appID]);
  return useEitherQuery(
    keys,
    () => {
      if (appID != null) {
        return services2.queryBillingService.queryByApp(from, till, appID);
      }
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );
};
