import { useMemo } from 'react';
import { UseQueryOptions, useQueryClient } from '@tanstack/react-query';

import { GenericFailure, UserId } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import {
  UserLaddersResponse,
  TeamBucket,
  LadderTemplate,
  AppLadderId,
  UserTeamLaddersResponse,
} from '@playq/services-clerk';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { laddersQueryTags } from './useQueryLadders';

const emptyArray: [] = [];

export const queryUserLaddersKeys: unknown[] = laddersQueryTags.concat('queryUserLadders');

export const useQueryUserLadders = (
  env: Environment,
  ladder: LadderTemplate | undefined,
  userId: UserId | undefined,
  options?: UseQueryOptions<UserLaddersResponse | UserTeamLaddersResponse | undefined, GenericFailure | Error>
) => {
  const id = ladder?.id;

  const keys = useMemo(() => queryUserLaddersKeys.concat(env, id, userId), [env, id, userId]);

  const queryClient = useQueryClient();

  const cachedData = queryClient.getQueryData<UserLaddersResponse | UserTeamLaddersResponse>(keys);

  const isTeamBucket = ladder?.ladderType instanceof TeamBucket;

  const enabled = useMemo(
    () =>
      (options?.enabled ?? true) &&
      ladder !== undefined &&
      id?.appId !== undefined &&
      id.ladderId !== undefined &&
      userId !== undefined,
    [id, ladder, userId, options?.enabled]
  );

  const res = useEitherQuery(
    keys,
    () => {
      if (isTeamBucket) {
        return services2.teamScoreSupportService.queryUserLadders(env, id as AppLadderId, userId as UserId);
      }
      return services2.userScoreSupportService.queryUserLadders(env, id as AppLadderId, userId as UserId);
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled,
    }
  );

  return { ...res, userLadders: res.data?.userLadders ?? cachedData?.userLadders ?? emptyArray };
};
