import { GenericFailure } from '@playq/services-shared';
import { AppGameEvent } from '@playq/octopus2-liveops';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { ICreateGameEventParams, IGameEventCreateOptionsVariables } from './types';
import { gameEventsServiceName, gameEventsService, createGameEventMutationKey } from './constants';

export const useGameEventCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<AppGameEvent, GenericFailure | Error, IGameEventCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreateGameEventParams, AppGameEvent, IInvokeScriptsHook>({
    serviceMethod: gameEventsService.create.bind(gameEventsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: gameEventsServiceName,
      mutationType: 'game event creation',
    },
    options: {
      mutationKey: createGameEventMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
