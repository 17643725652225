import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { CouponID, CouponTemplateID } from '@playq/services-bookkeeper';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { CouponType } from '/component/Economy/Coupons/types';

import { getCouponMutationKey } from './getCouponMutationKey';
import { deleteCouponMutationKey } from './constants';

export const useCouponDelete = (
  type: CouponType,
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, CouponID | CouponTemplateID>
) =>
  useEitherMutation(
    (id) =>
      id instanceof CouponID ? services2.massCouponsService.delete(id) : services2.targetedCouponsService.delete(id),
    {
      mutationKey: getCouponMutationKey(type, deleteCouponMutationKey),
      ...options,
    }
  );
