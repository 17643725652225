import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { WebEventID, WebEvent } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { webEventGetKeys } from './constants';

export const useWebEventGet = (
  id?: WebEventID,
  options?: UseQueryOptions<WebEvent | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => webEventGetKeys.concat(id), [id]);

  const res = useEitherQuery(keys, () => services2.webEventsService.retrieve(id as WebEventID), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
    enabled: options?.enabled !== false && id !== undefined,
  });

  return {
    webEvent: res.data,
    ...res,
    tags: keys,
  };
};
