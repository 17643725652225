import { UseMutationOptions } from '@tanstack/react-query';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GenericFailure } from '@playq/services-shared';
import { AppBlueprintEntity } from '@playq/octopus2-analytics';

import { useEitherMutation } from '/api';

import { blueprintsService } from './constants';
import { IBlueprintCreationParams } from './types';

export const useBlueprintCreate = (
  options?: UseMutationOptions<AppBlueprintEntity, GenericFailure | Error, IBlueprintCreationParams>
) => useEitherMutation(({ appID, data }: IBlueprintCreationParams) => blueprintsService.create(appID, data), options);
