import { CreatePostbackData } from '@playq/services-adhawk-scheduler';
import { Scheduling } from '@playq/services-jobot-scheduler';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { setupPostbackIntegrationMutationKey } from './constants';

export const useIntegrationSetupPostback = (
  options?: UseMutationOptionsExtended<Scheduling, GenericFailure | Error, CreatePostbackData>
) => {
  return useEitherMutation((createData) => services2.integrationsService.setupPostback(createData), {
    mutationKey: setupPostbackIntegrationMutationKey,
    ...options,
  });
};
