import { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppEntityVersion } from '@playq/octopus2-apps';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';

import { IAppConfigUpsertOptionsVariables, IUpsertAppConfigParams } from './types';
import { appConfigService, appConfigServiceName } from './constants';

export const useConfigUpsertScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptions<AppEntityVersion, GenericFailure | Error, IAppConfigUpsertOptionsVariables>
): UseMutationResult<AppEntityVersion, GenericFailure | Error, IAppConfigUpsertOptionsVariables> =>
  useEitherMutationScripted<IUpsertAppConfigParams, AppEntityVersion, IInvokeScriptsHook>({
    serviceMethod: appConfigService.upsert.bind(appConfigService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appConfigServiceName,
      mutationType: 'app config saving',
    },
    options,
  });
