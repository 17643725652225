import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

import { removeFilesMutationKey } from './constants';
import { UseFileDeleteBatchOptions } from './types';

export const useFileDeleteBatch = (options?: UseFileDeleteBatchOptions) => {
  return useEitherMutation((ids) => services2.filesService.deleteBatch(ids), {
    mutationKey: removeFilesMutationKey,
    ...options,
  });
};
