import { QueryKey, MutationKey } from '@tanstack/react-query';

import { CouponType } from '/component/Economy/Coupons/types';

export const couponsQueryKeys: Record<CouponType, QueryKey> = {
  [CouponType.mass]: ['coupons', 'mass', 'query'],
  [CouponType.targeted]: ['coupons', 'targeted', 'query'],
};

export const couponGetKeys: Record<CouponType, QueryKey> = {
  [CouponType.mass]: ['coupon', 'mass', 'get'],
  [CouponType.targeted]: ['coupon', 'targeted', 'get'],
};

export const createCouponMutationKey: MutationKey = ['coupon', 'mutation', 'create'];
export const updateCouponMutationKey: MutationKey = ['coupon', 'mutation', 'update'];
export const deleteCouponMutationKey: MutationKey = ['coupon', 'mutation', 'delete'];
