import { CouponID, CouponTemplate, CouponTemplateID, MassCoupon } from '@playq/services-bookkeeper';
import {
  CouponsTemplateResponse,
  EnvironmentCouponsTemplate,
  EnvironmentMassCoupons,
  MassCouponsResponse,
} from '@playq/octopus2-economy';

import { MutateCustomFieldsArgs } from '/api/service-hooks';

export const setAllToCouponsResponse = (
  response: MassCouponsResponse | CouponsTemplateResponse,
  newEntities: (MassCoupon | CouponTemplate)[]
) => {
  if (response instanceof MassCouponsResponse) {
    response.all = newEntities as MassCoupon[];
  }
  if (response instanceof CouponsTemplateResponse) {
    response.all = newEntities as CouponTemplate[];
  }
};

export const createNewCouponsResponse = (prevResponse: MassCouponsResponse | CouponsTemplateResponse) =>
  prevResponse instanceof MassCouponsResponse
    ? new MassCouponsResponse(prevResponse.serialize())
    : new CouponsTemplateResponse(prevResponse.serialize());

const mutateItemDetailsAfterCouponDeleting = ({
  response,
  nextResponse,
}: MutateCustomFieldsArgs<MassCouponsResponse | CouponsTemplateResponse, CouponID | CouponTemplateID>) => {
  response.itemDetails = { ...response.itemDetails, ...nextResponse?.itemDetails };
};

type MutateEnvAfterCouponDeleting = Omit<
  Required<MutateCustomFieldsArgs<MassCouponsResponse | CouponsTemplateResponse, CouponID | CouponTemplateID>>,
  'response'
> & {
  env: 'sandbox' | 'live';
  envs: EnvironmentMassCoupons | EnvironmentCouponsTemplate;
};

const mutateEnvAfterCouponDeleting = ({ env, envs, nextResponse, id }: MutateEnvAfterCouponDeleting) => {
  const isPromoted = nextResponse.envs[env].some((promotedID) => promotedID.serialize() === id.serialize?.());
  if (isPromoted) {
    (envs[env] as (typeof id)[]).push(id);
  }
};

const mutateMassCouponsResponseEnvs = (
  response: MassCouponsResponse,
  nextResponse: MassCouponsResponse,
  id: CouponID
) => {
  const newEnvs = new EnvironmentMassCoupons(response.envs.serialize());
  mutateEnvAfterCouponDeleting({ env: 'sandbox', envs: newEnvs, nextResponse, id });
  mutateEnvAfterCouponDeleting({ env: 'live', envs: newEnvs, nextResponse, id });

  response.envs = newEnvs;
};

const mutateTemplateCouponsResponseEnvs = (
  response: CouponsTemplateResponse,
  nextResponse: CouponsTemplateResponse,
  id: CouponTemplateID
) => {
  const newEnvs = new EnvironmentCouponsTemplate(response.envs.serialize());
  mutateEnvAfterCouponDeleting({ env: 'sandbox', envs: newEnvs, nextResponse, id });
  mutateEnvAfterCouponDeleting({ env: 'live', envs: newEnvs, nextResponse, id });

  response.envs = newEnvs;
};

export const mutateEnvsAndItemDetailsAfterCouponDeleting = ({
  response,
  id,
  nextResponse,
}: MutateCustomFieldsArgs<MassCouponsResponse | CouponsTemplateResponse, CouponID | CouponTemplateID>) => {
  if (!id || !nextResponse) {
    return;
  }
  mutateItemDetailsAfterCouponDeleting({
    response,
    id,
    nextResponse,
  });
  if (
    response instanceof MassCouponsResponse &&
    nextResponse instanceof MassCouponsResponse &&
    id instanceof CouponID
  ) {
    mutateMassCouponsResponseEnvs(response, nextResponse, id);
  }
  if (
    response instanceof CouponsTemplateResponse &&
    nextResponse instanceof CouponsTemplateResponse &&
    id instanceof CouponTemplateID
  ) {
    mutateTemplateCouponsResponseEnvs(response, nextResponse, id);
  }
};
