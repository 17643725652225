import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';
import { AppEntityContentResponse } from '@playq/octopus2-apps';

import { useEitherQuery } from '/api/service-hooks';

import { appConfigService } from './constants';

export const configRetrieveKeys: unknown[] = ['config', 'retrieve'];

export const useConfigRetrieve = (
  appID?: AppID,
  version?: number,
  options?: UseQueryOptions<AppEntityContentResponse | undefined, GenericFailure | Error>
) => {
  return useEitherQuery(
    configRetrieveKeys.concat(appID?.serialize(), version),
    () => appConfigService.retrieve(appID as AppID, version),
    { ...options, enabled: options?.enabled !== false && appID !== undefined }
  );
};
