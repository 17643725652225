import { useCallback } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { SchedulerWebHook, SchedulerWebHookId } from '@playq/services-jobot-scheduler';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const webHookGetKeys: unknown[] = ['admin', 'webhook', 'get'];

export const useWebHookGet = (
  id?: SchedulerWebHookId,
  options?: UseQueryOptions<SchedulerWebHook | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    webHookGetKeys.concat(id),
    () => {
      if (id) {
        return services2.intergrationsWebHookService.retrieve(id);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const mutateDeletedWebhook = useCallback(
    (webHook: SchedulerWebHook) => {
      if (res.data) {
        mutate(webHook);
      }
    },
    [mutate, res.data]
  );

  return {
    webHook: res.data,
    ...res,
    mutate,
    mutateDeletedWebhook,
  };
};
