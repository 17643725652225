import { useCallback } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import {
  AppCloudFunction,
  AppCloudFunctionResponse,
  CloudFunctionCreateData,
  CloudFunctionID,
} from '@playq/octopus2-cloud';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

export const cloudFunctionRetrieveKeys: unknown[] = ['cloud-functions', 'service', 'retrieve'];

export const useCloudFunctionRetrieve = (
  id?: CloudFunctionID,
  version?: number,
  options?: UseQueryOptions<AppCloudFunctionResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    cloudFunctionRetrieveKeys.concat(id, version),
    () => services2.cloudFunctionsService.retrieve(id as CloudFunctionID, version),
    { ...options, enabled: options?.enabled !== false && id !== undefined }
  );

  const mutateUpdatedItem = useCallback(
    (updatedData: CloudFunctionCreateData) => {
      if (res.data) {
        const cloned = new AppCloudFunctionResponse(res.data.serialize());
        cloned.lastVersion += 1;
        cloned.cloudFunction = new AppCloudFunction(res.data.cloudFunction.serialize());
        cloned.cloudFunction.name = updatedData.name;
        cloned.cloudFunction.tags = updatedData.tags;
        cloned.cloudFunction.definition = updatedData.definition;
        mutate(cloned);
      }
    },
    [mutate, res.data]
  );

  return {
    ...res,
    mutate,
    mutateUpdatedItem,
  };
};
