import { UseMutationOptions } from '@tanstack/react-query';

import { Environment } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { CloudFunctionID, CloudFunctionInvokeResponse } from '@playq/octopus2-cloud';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const useCloudFunctionInvoke = (
  options?: UseMutationOptions<
    CloudFunctionInvokeResponse,
    GenericFailure | Error,
    {
      id: CloudFunctionID;
      env: Environment;
      payload: string;
    }
  >
) => {
  return useEitherMutation(({ id, env, payload }) => services2.cloudFunctionsService.invoke(id, env, payload), options);
};
