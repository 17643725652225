import { UseMutationOptions } from '@tanstack/react-query';

import { GroupID } from '@playq/octopus2-admin';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { UserID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const useRemoveUser = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, { groupID: GroupID; userID: UserID }>
) => {
  return useEitherMutation(({ groupID, userID }) => services2.adminGroupsService.removeUser(groupID, userID), options);
};
