import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CheckFileRequest, CheckFileResponse, FileState } from '@playq/octopus2-files';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { checkFileKeys } from './constants';

export type UseFileCheckArgs = { appID?: AppID; check?: CheckFileRequest };

export const useCheckFile = (
  { check }: UseFileCheckArgs,
  options?: UseQueryOptions<CheckFileResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => checkFileKeys.concat(check), [check]);

  const res = useEitherQuery(keys, () => services2.filesService.checkFile(check as CheckFileRequest), {
    onError: console.error,
    ...options,
    enabled: options?.enabled !== false && check !== undefined,
  });

  return {
    ...res,
    status: res.data?.state ?? FileState.NotFound,
    tags: keys,
  };
};

export type UseCheckFileType = typeof useCheckFile;
