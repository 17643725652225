import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CopyEntityID, CopyEntityResponse } from '@playq/octopus2-creatives';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { retrieveCopyTags } from './constants';

export const useCopyRetrieve = (
  id?: CopyEntityID,
  options?: UseQueryOptions<CopyEntityResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => retrieveCopyTags.concat(id), [id]);

  const res = useEitherQuery(keys, () => services2.copiesService.retrieve(id as CopyEntityID), {
    ...options,
    enabled: options?.enabled !== false && id !== undefined,
  });

  return {
    ...res,
    tags: keys,
  };
};
