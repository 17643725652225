import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { GameEventID, AppGameEventResponse } from '@playq/octopus2-liveops';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { gameEventRetrieveQueryKey } from './constants';

export const useGetGameEvent = (
  id?: GameEventID,
  version?: number,
  options?: UseQueryOptions<AppGameEventResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => gameEventRetrieveQueryKey.concat(id, version), [id, version]);
  const res = useEitherQuery(keys, () => services2.appGameEventsService.retrieve(id as GameEventID, version), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
    enabled: options?.enabled !== false && id !== undefined,
  });

  return {
    ...res,
    tags: keys,
  };
};
