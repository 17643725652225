import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { UserID } from '@playq/octopus-common';
import { PermTarget, GroupID } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { unwrapEither } from '/api/service-hooks/unwrapEither';
import { groupServiceBriefTags } from '/api/hooks/corp/groupsService';
import { usersServicePublicTags } from '/api/hooks/usersService';

export const usePermGetTargetsData = (targets: PermTarget[]) => {
  const { users, usersSerialized, groups, groupsSerialized } = useMemo(() => {
    const usersArr = targets.filter((t) => t instanceof UserID) as UserID[];
    const groupsArr = targets.filter((target) => target instanceof GroupID) as GroupID[];
    return {
      users: usersArr,
      usersSerialized: usersArr.map((u) => u.serialize()),
      groups: groupsArr,
      groupsSerialized: groupsArr.map((g) => g.serialize()),
    };
  }, [targets]);

  return useQuery({
    queryKey: usersServicePublicTags.concat(usersSerialized).concat(groupServiceBriefTags.concat(groupsSerialized)),
    queryFn: () =>
      Promise.all([
        unwrapEither(services2.adminGroupsService.briefFor(groups)),
        unwrapEither(services2.usersService.retrievePublicFor(users)),
      ]).then((res) => res.flat()),
  });
};
