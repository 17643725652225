import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { WebEventsSort, WebEventsSortField, QueryWebEventsResponse, WebEvent } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { IQuery } from '/common/models';

import { webEventsQueryKeys } from './constants';

const emptyArray: [] = [];

export const useWebEventsQuery = (
  query: IQuery<WebEventsSortField, WebEventsSort>,
  options?: UseQueryOptionsExtended<QueryWebEventsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const { iterator, sortBy, filterBy } = query;

  const keys = useMemo(() => webEventsQueryKeys.concat(iterator, sortBy, filterBy), [iterator, sortBy, filterBy]);

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.webEventsService.queryWebEvents(iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [query.iterator, query.sortBy, query.filterBy],
    serviceMethod: services2.webEventsService.queryWebEvents.bind(services2.webEventsService),
  });

  const mutateDeletedWebEvent = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (wE?: WebEvent) => wE?.id,
    getEntities: (r: QueryWebEventsResponse) => r.entities,
    setEntities: (r: QueryWebEventsResponse, newEntities: WebEvent[]) => {
      r.entities = newEntities;
    },
    createNewResponse: (prevResponse: QueryWebEventsResponse) => new QueryWebEventsResponse(prevResponse.serialize()),
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(webEventsQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    webEvents: res.data?.entities || emptyArray,
    total: res.data?.total ?? 0,
    tags: keys,
    mutate,
    mutateDeletedWebEvent,
    removeQueries,
    refetch,
  };
};
