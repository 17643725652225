import { GenericFailure, UserId } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { UpdateScoreResponse, AppLadderInstanceId, AppTeamLadderInstanceId } from '@playq/services-clerk';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { queryLadderInstanceScoresKeys } from './useQueryLadderInstanceScores';

export type AddScorePayloadType = {
  env: Environment;
  instance: AppLadderInstanceId | AppTeamLadderInstanceId;
  userId: UserId;
  score: number;
  meta: {
    [key: string]: string;
  };
  teamSegment: string | undefined;
};

export const useUpdateScore = (
  options?: UseMutationOptionsExtended<UpdateScoreResponse, GenericFailure | Error, AddScorePayloadType>
) =>
  useEitherMutation(
    ({ env, instance, userId, score, meta, teamSegment }) => {
      if (instance instanceof AppTeamLadderInstanceId) {
        return services2.teamScoreSupportService.updateScore(env, instance, userId, score, meta, teamSegment);
      }
      return services2.userScoreSupportService.updateScore(env, instance, userId, score, meta);
    },
    {
      removeQueriesKeys: queryLadderInstanceScoresKeys,
      ...options,
    }
  );
