import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { CopyEntityID } from '@playq/octopus2-creatives';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteCopyMutationKey } from './constants';

export const useCopyDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, CopyEntityID>
) =>
  useEitherMutation((copyID) => services2.copiesService.delete(copyID), {
    mutationKey: deleteCopyMutationKey,
    ...options,
  });
