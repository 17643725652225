import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { LadderTemplate, LadderDimensionsResponse, TeamBucket, AppLadderId } from '@playq/services-clerk';

import { throwGenericFailure } from '/helpers';
import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { laddersQueryTags } from './useQueryLadders';

const emptyArray: [] = [];

export const queryTeamsLadderInstanceDimensionsKeys = laddersQueryTags.concat('queryTeamLadderInstancesDimensions');

export const useQueryTeamLadderInstancesDimensions = (
  env: Environment,
  ladder: LadderTemplate | undefined,
  iterator: OffsetLimit = new OffsetLimit({ offset: 0, limit: 10 }),
  options?: UseQueryOptions<LadderDimensionsResponse | undefined, GenericFailure | Error>
) => {
  const id = ladder?.id;

  const keys = useMemo(() => queryTeamsLadderInstanceDimensionsKeys.concat(env, id, iterator), [env, id, iterator]);

  const isValid = useMemo(
    () => id?.appId !== undefined && id?.ladderId !== undefined && ladder?.ladderType instanceof TeamBucket,
    [id, ladder?.ladderType]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && isValid, [options?.enabled, isValid]);

  const res = useEitherQuery(
    keys,
    () => services2.teamScoreSupportService.queryTeamLadderInstanceDimensions(env, id as AppLadderId, iterator),
    {
      keepPreviousData: true,
      onError: (err) => {
        snackbarService.error(`queryLadders-${err.message}`);
        if (err instanceof GenericFailure) {
          throwGenericFailure(err);
        }
      },
      ...options,
      enabled,
    }
  );

  const dimensions = useMemo(() => res.data?.dimensions, [res.data?.dimensions]);

  return { ...res, tags: keys, dimensions: dimensions || emptyArray };
};
