import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { Group, GroupID } from '@playq/octopus2-admin';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const groupGetKeys: unknown[] = ['admin', 'group', 'get'];

export const useGroupGet = (id?: GroupID, options?: UseQueryOptions<Group | undefined, GenericFailure | Error>) => {
  const keys = useMemo(() => groupGetKeys.concat(id), [id]);

  const { mutate, ...res } = useEitherQuery(keys, () => services2.adminGroupsService.retrieve(id as GroupID), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
    enabled: options?.enabled !== false && id !== undefined,
  });

  const mutateDeletedGroup = useCallback(
    (group: Group) => {
      if (res.data) {
        mutate(group);
      }
    },
    [mutate, res.data]
  );

  return {
    group: res.data,
    ...res,
    tags: keys,
    mutate,
    mutateDeletedGroup,
  };
};
