import { useMemo } from 'react';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { QueryCredentialsResponse, CredentialsSort } from '@playq/octopus2-auth';
import { Filter } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { UseQueryOptionsExtended, useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

const emptyArray: [] = [];
const emptyFilterBy: { [key: string]: Filter } = {};

export const queryCredentialsKeys: unknown[] = ['service', 'public', 'query', 'credentials'];

export const useQueryCredentials = (
  iterator: OffsetLimit,
  sortBy: CredentialsSort[] = emptyArray,
  filterBy: { [key: string]: Filter } = emptyFilterBy,
  options?: UseQueryOptionsExtended<QueryCredentialsResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => queryCredentialsKeys.concat(iterator, sortBy, filterBy), [iterator, sortBy, filterBy]);

  const res = useEitherQuery(keys, () => services2.credentialsService.queryCredentials(iterator, sortBy, filterBy), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [iterator, sortBy, filterBy],
    serviceMethod: services2.credentialsService.queryCredentials.bind(services2.credentialsService),
  });

  return { ...res, credentials: res.data?.entities || emptyArray, total, tags: keys };
};
