import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Updater } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { FlowsSort, QueryFlowsResponse, FlowBrief, FlowPromotions } from '@playq/octopus2-liveops';
import { FlowID, AppID } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';

import { services2 } from '/api/services2';
import {
  useEitherQuery,
  useQueryPrefetch,
  UseQueryOptionsExtended,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { IEntityPromotion } from '/api/hooks/promotions';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { iteratorMaxLimit } from '/constants';
import { QueryFilter } from '/common/models';

import { createFlowsResponseAfterFlowDeleting, mutatePromotionsAndFeaturesAfterFlowDeleting } from './helpers';

const emptyArray: [] = [];

const defaultQuery = {
  iterator: new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 }),
  sortBy: [],
  filterBy: {},
};

export const flowQueryKeys: unknown[] = ['flows', 'service', 'query'];

export const useFlowsQuery = (
  iterator: OffsetLimit,
  sortBy: FlowsSort[] = emptyArray,
  filterBy: QueryFilter = defaultQuery.filterBy,
  options?: UseQueryOptionsExtended<QueryFlowsResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const queryClient = useQueryClient();

  const keys = useMemo(
    () => flowQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, filterBy, iterator, sortBy]
  );

  const disabled = useMemo(() => !appID, [appID]);

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.flowsService.queryFlows(appID as AppID, iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled: options?.enabled !== false && !disabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch !== false && appID !== undefined,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy],
    serviceMethod: services2.flowsService.queryFlows.bind(services2.flowsService),
  });

  const mutateUpdatePromotions = useCallback(
    (promotions: Updater<IEntityPromotion<FlowID>[], IEntityPromotion<FlowID>[]>) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const response = new QueryFlowsResponse(prevResponse.serialize());
        response.flows = prevResponse.flows;
        response.iterator = prevResponse.iterator;
        response.features = prevResponse.features;

        const nextPromtions = typeof promotions === 'function' ? promotions(response.promotions) : promotions;
        response.promotions = nextPromtions.map(
          (p) => new FlowPromotions({ id: p.id.serialize(), promotions: p.promotions.serialize() })
        );
        return response;
      });
    },
    [mutate]
  );

  const mutateDeletedFlow = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (f?: FlowBrief) => f?.id,
    getEntities: (r: QueryFlowsResponse) => r.flows,
    setEntities: (r: QueryFlowsResponse, newEntities: FlowBrief[]) => {
      r.flows = newEntities;
    },
    createNewResponse: createFlowsResponseAfterFlowDeleting,
    mutateCustomFields: mutatePromotionsAndFeaturesAfterFlowDeleting,
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(flowQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    flows: res.data?.flows || emptyArray,
    promotions: (res.data?.promotions || emptyArray) as IEntityPromotion<FlowID>[],
    total,
    tags: keys,
    refetch,
    mutateUpdatePromotions,
    mutateDeletedFlow,
    removeQueries,
  };
};
