import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { useEitherMutation } from '/api';

import { blueprintsService } from './constants';
import { IBlueprintUpdateParams } from './types';

export const useBlueprintUpdate = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, IBlueprintUpdateParams>
) =>
  useEitherMutation(
    ({ id, data, version }: IBlueprintUpdateParams) => blueprintsService.update(id, data, version),
    options
  );
