import { UseMutationOptions } from '@tanstack/react-query';

import { CreativeAssetsPolicy } from '@playq/octopus2-files';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const usePolicyAssetsUpdate = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, CreativeAssetsPolicy>
) => {
  return useEitherMutation((policy) => services2.creativeAssetsAdminService.updatePolicy(policy), options);
};
