import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure } from '@playq/services-shared';
import { SystemEvents } from '@playq/octopus2-analytics';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

export const eventsMapperRetrieveKeys: unknown[] = ['events-mapper', 'retrieve'];
export const systemEventsRetrieveKeys: unknown[] = eventsMapperRetrieveKeys.concat('system');

const emptyArray: [] = [];

export const useRetrieveSystemEvents = (
  options?: UseQueryOptions<SystemEvents | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const res = useEitherQuery(
    systemEventsRetrieveKeys.concat(appID),
    () => {
      if (appID !== undefined) {
        return services2.appEventsMapperService.retrieveSystemEvents(appID);
      }
    },
    {
      onError: (err) => {
        snackbarService.genericFailure(err);
      },
      keepPreviousData: true,
      ...options,
    }
  );

  return {
    ...res,
    events: res?.data?.events || emptyArray,
  };
};
