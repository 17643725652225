import { SchedulingId } from '@playq/services-jobot-scheduler';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteIntegrationMutationKey } from './constants';

export const useIntegrationDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, SchedulingId>
) => {
  return useEitherMutation((id) => services2.integrationsService.cancel(id), {
    mutationKey: deleteIntegrationMutationKey,
    ...options,
  });
};
