import { UseMutationOptions } from '@tanstack/react-query';

import { SchedulerWebHookId } from '@playq/services-jobot-scheduler';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const useWebHookDelete = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, SchedulerWebHookId>
) => {
  return useEitherMutation((id) => services2.intergrationsWebHookService.delete(id), {
    ...options,
    onError: (err) => snackbarService.genericFailure(err),
  });
};
