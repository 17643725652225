import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { UserFull } from '@playq/octopus2-auth';
import { UserID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const userGetKeys: unknown[] = ['admin', 'user', 'get'];

export const useUserGet = (id?: UserID, options?: UseQueryOptions<UserFull | undefined, GenericFailure | Error>) => {
  const keys = useMemo(() => userGetKeys.concat(id), [id]);

  const { mutate, ...res } = useEitherQuery(
    keys,
    () => {
      if (id) {
        return services2.adminUsersService.retrieve(id);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const mutateDeletedUser = useCallback(
    (user: UserFull) => {
      if (res.data) {
        mutate(user);
      }
    },
    [mutate, res.data]
  );

  return {
    user: res.data,
    ...res,
    tags: keys,
    mutate,
    mutateDeletedUser,
  };
};
