import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ExperimentsPolicy } from '@playq/octopus2-analytics';

import { useEitherQuery } from '/api/service-hooks';

import { appExperimentsService } from './constants';

export const experimentsPolicyKeys: unknown[] = ['experiments', 'policy'];

const emptyArray: [] = [];

export const useExperimentsPolicy = (
  options?: UseQueryOptions<ExperimentsPolicy | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(experimentsPolicyKeys, () => appExperimentsService.policy(), options);

  return { ...res, options: res.data?.classes || emptyArray };
};
