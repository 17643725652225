import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { FileResponse } from '@playq/octopus2-files';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IFileUpdateOptionsVariables, IUpdateFileParams } from './types';
import { filesService, filesServiceName, updateFileMutationKey, FILE_SAVE_HOOK_NAME } from './constants';

export const useFileUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IFileUpdateOptionsVariables,
    FileResponse | undefined
  >
) =>
  useEitherMutationScripted<IUpdateFileParams, CommonSuccess, IInvokeScriptsHook, FileResponse | undefined>({
    serviceMethod: filesService.update.bind(filesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: filesServiceName,
      mutationType: FILE_SAVE_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: updateFileMutationKey,
      ...options,
    },
  });
