import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppEntityContentResponse } from '@playq/octopus2-apps';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const useConfigRetrieveSingleTime = (
  options?: UseMutationOptions<
    AppEntityContentResponse | undefined,
    GenericFailure | Error,
    { id: AppID; version?: number }
  >
) =>
  useEitherMutation(({ id, version }) => services2.appConfigService.retrieve(id, version), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
