import { ExperimentID, QueryExperimentsResponse } from '@playq/octopus2-analytics';

import { MutateCustomFieldsArgs } from '/api/service-hooks';
import { filterById } from '/helpers';

export const mutatePromotionsAndFeaturesAfterExperimentDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<QueryExperimentsResponse, ExperimentID>) => {
  if (!id || !nextResponse) {
    return;
  }
  const nextExpPromotions = nextResponse.promotions.find(filterById(id, true));
  if (nextExpPromotions !== undefined) {
    response.promotions.push(nextExpPromotions);
  }
  const nextExpFeatures = nextResponse.features.find(filterById(id, true));
  if (nextExpFeatures !== undefined) {
    response.features.push(nextExpFeatures);
  }
};

export const createExperimentsResponseAfterExperimentDeleting = (
  prevResponse: QueryExperimentsResponse,
  removedExpID?: ExperimentID | ExperimentID[]
) => {
  const newResponse = new QueryExperimentsResponse(prevResponse.serialize());

  if (removedExpID !== undefined) {
    newResponse.features = prevResponse.features.filter(filterById(removedExpID));
  }

  return newResponse;
};
