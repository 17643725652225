import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { AppCloudFunctionResponse, CloudFunctionID, CloudFunctionCreateData } from '@playq/octopus2-cloud';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { updateCloudFunctionMutationKey } from './constants';

export const useCloudFunctionUpdate = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    {
      id: CloudFunctionID;
      data: CloudFunctionCreateData;
      version?: number;
    },
    AppCloudFunctionResponse | undefined
  >
) =>
  useEitherMutation(({ id, data, version }) => services2.cloudFunctionsService.update(id, data, version), {
    mutationKey: updateCloudFunctionMutationKey,
    ...options,
  });
