import { useQueries, QueriesOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppBlueprintEntityResponse } from '@playq/octopus2-analytics';

import { logSnackbarError } from '/common/snackbarService';

import { blueprintsService, blueprintsServiceName } from './constants';
import { IUseBlueprintsRetrieveParams, IGetBlueprintIDForQueryParams } from './types';
import { getBlueprintIDForQuery } from './helpers';

export const useBlueprintsRetrieve = (
  { appID, blueprintIDs }: IUseBlueprintsRetrieveParams,
  optionsList: QueriesOptions<(AppBlueprintEntityResponse | undefined)[], (Error | GenericFailure)[]> = []
) => {
  const isQueryEnabled = appID !== undefined;

  return useQueries({
    queries:
      blueprintIDs?.map((id, i) => ({
        queryKey: [blueprintsServiceName, 'retrieve', appID, id, 'latest'],
        queryFn: blueprintsService.retrieve.bind(
          blueprintsService,
          getBlueprintIDForQuery({ appID, blueprintID: id, isQueryEnabled } as IGetBlueprintIDForQueryParams),
          undefined
        ),
        onError: logSnackbarError,
        ...optionsList[i],
      })) ?? [],
  });
};
