import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { CouponTemplate, MassCoupon } from '@playq/services-bookkeeper';
import { CouponTemplateGetResponse, MassCouponGetResponse } from '@playq/octopus2-economy';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { CouponType } from '/component/Economy/Coupons/types';

import { updateCouponMutationKey } from './constants';
import { getCouponMutationKey } from './getCouponMutationKey';

export type UpdateCouponPayload = MassCoupon | CouponTemplate;

export const useCouponUpdate = (
  type: CouponType,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    UpdateCouponPayload,
    MassCouponGetResponse | CouponTemplateGetResponse | undefined
  >
) =>
  useEitherMutation(
    (coupon) =>
      coupon instanceof MassCoupon
        ? services2.massCouponsService.update(coupon)
        : services2.targetedCouponsService.update(coupon),
    {
      mutationKey: getCouponMutationKey(type, updateCouponMutationKey),
      ...options,
    }
  );
