import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { BillingConfiguration } from '@playq/services-taxman';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

export const corpBillingConfiguredQueryKeys: unknown[] = ['corp', 'billing', 'configured', 'query'];

export const useAdminBillingConfiguredQuery = (
  options?: UseQueryOptions<BillingConfiguration | undefined, GenericFailure | Error>
) => {
  return useEitherQuery(corpBillingConfiguredQueryKeys, () => services2.adminBillingService.getConfig(), {
    keepPreviousData: true,
    ...options,
  });
};
