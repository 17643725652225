import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { GameEventID } from '@playq/octopus2-liveops';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteGameEventMutationKey } from './constants';

export const useGameEventDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, GameEventID>
) =>
  useEitherMutation((gameEvent) => services2.appGameEventsService.delete(gameEvent), {
    mutationKey: deleteGameEventMutationKey,
    ...options,
  });
