import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Environment, Filter, AppID } from '@playq/octopus-common';
import { CouponID, CouponTemplate, CouponTemplateID, MassCoupon } from '@playq/services-bookkeeper';
import {
  CouponsSort,
  CouponsTemplateResponse,
  EnvironmentCouponsTemplate,
  EnvironmentMassCoupons,
  ItemDetails,
  MassCouponsResponse,
} from '@playq/octopus2-economy';

import { services2 } from '/api/services2';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { CouponType } from '/component/Economy/Coupons/types';
import { QueryHelpers } from '/helpers/query';

import { CouponPromotionPayload } from './useCouponPromotion';
import { couponsQueryKeys } from './constants';
import { QueryCouponsServiceType } from './types';
import {
  createNewCouponsResponse,
  mutateEnvsAndItemDetailsAfterCouponDeleting,
  setAllToCouponsResponse,
} from './helpers';

const emptyArray: [] = [];
const defaultItemDetails: Record<string, ItemDetails | undefined> = {};
const getDefaultEnvs = (type: CouponType) =>
  type === CouponType.mass ? new EnvironmentMassCoupons().serialize() : new EnvironmentCouponsTemplate().serialize();
const emptyFilterBy: {
  [key: string]: Filter;
} = {};

export const useCouponsQuery = (
  type: CouponType,
  iterator: OffsetLimit = QueryHelpers.getMaxIterator(),
  sortBy: CouponsSort[] = emptyArray,
  filterBy: {
    [key: string]: Filter;
  } = emptyFilterBy,
  options?: UseQueryOptionsExtended<MassCouponsResponse | CouponsTemplateResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const queryClient = useQueryClient();

  const couponService = useMemo(
    () => services2[type === CouponType.mass ? 'massCouponsService' : 'targetedCouponsService'],
    [type]
  );

  const keys = useMemo(
    () => couponsQueryKeys[type].concat(appID, iterator, sortBy, filterBy),
    [appID, type, iterator, sortBy, filterBy]
  );

  const isValid = useMemo(() => appID !== undefined, [appID]);
  const enabled = useMemo(() => (options?.enabled ?? true) && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && isValid,
    [options?.enablePrefetch, isValid]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => couponService.queryCoupons(appID as AppID, iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy],
    serviceMethod: couponService.queryCoupons.bind(couponService) as QueryCouponsServiceType,
  });

  const mutatePromotion = useCallback(
    ({ id, env, demote }: CouponPromotionPayload) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const couponResponse = id instanceof CouponID ? new MassCouponsResponse() : new CouponsTemplateResponse();
        couponResponse.all = prevResponse.all;
        couponResponse.itemDetails = prevResponse.itemDetails;
        couponResponse.total = prevResponse.total;
        couponResponse.iterator = prevResponse.iterator;
        couponResponse.envs = prevResponse.envs;

        const envProp = env === Environment.Sandbox ? 'sandbox' : 'live';
        if (id instanceof CouponID) {
          if (demote) {
            couponResponse.envs[envProp] = (couponResponse.envs as EnvironmentMassCoupons)[envProp].filter(
              (couponID) => couponID.serialize() !== id.serialize()
            );
          } else {
            (couponResponse.envs as EnvironmentMassCoupons)[envProp].push(id);
          }
        } else if (demote) {
          couponResponse.envs[envProp] = (couponResponse.envs as EnvironmentCouponsTemplate)[envProp].filter(
            (couponID) => couponID.serialize() !== id.serialize()
          );
        } else {
          (couponResponse.envs as EnvironmentCouponsTemplate)[envProp].push(id);
        }
        return couponResponse;
      });
    },
    [mutate]
  );

  const mutateDeletedCoupon = useMutateQueryDataAfterEntityDeleting<
    MassCouponsResponse | CouponsTemplateResponse,
    CouponID | CouponTemplateID,
    MassCoupon | CouponTemplate
  >({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (coupon?: MassCoupon | CouponTemplate) => coupon?.id,
    getEntities: (r: MassCouponsResponse | CouponsTemplateResponse) => r.all,
    setEntities: setAllToCouponsResponse,
    createNewResponse: createNewCouponsResponse,
    mutateCustomFields: mutateEnvsAndItemDetailsAfterCouponDeleting,
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(couponsQueryKeys[type]);
  }, [queryClient, type]);

  return {
    ...res,
    coupons: res.data?.all || emptyArray,
    envs: res.data?.envs?.serialize() || getDefaultEnvs(type),
    itemsDetails: res.data?.itemDetails || defaultItemDetails,
    total,
    tags: keys,
    mutatePromotion,
    mutateDeletedCoupon,
    removeQueries,
    refetch,
  };
};
