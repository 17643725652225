import { GenericFailure } from '@playq/services-shared';
import { AppExperiment } from '@playq/octopus2-analytics';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { ICreateExperimentParams, IExperimentCreateOptionsVariables } from './types';
import { appExperimentsService, appExperimentsServiceName, createExperimentMutationKeys } from './constants';

export const useExperimentCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options: UseMutationOptionsExtended<AppExperiment, GenericFailure | Error, IExperimentCreateOptionsVariables>
) => {
  return useEitherMutationScripted<ICreateExperimentParams, AppExperiment, IInvokeScriptsHook>({
    serviceMethod: appExperimentsService.create.bind(appExperimentsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appExperimentsServiceName,
      mutationType: 'experiment creation',
    },
    options: {
      mutationKey: createExperimentMutationKeys,
      onError: onResponseError,
      ...options,
    },
  });
};
