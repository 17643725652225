import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { IntegrationsMetaResponse } from '@playq/services-adhawk-scheduler';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const corpIntegrationsMetaQueryKeys: unknown[] = ['corp', 'integrations', 'meta', 'query'];

export const useIntegrationsMetaQuery = (
  options?: UseQueryOptions<IntegrationsMetaResponse | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(corpIntegrationsMetaQueryKeys, () => services2.integrationsService.retrieveMeta(), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });

  return {
    ...res,
    available: res.data?.available,
    postbacks: res.data?.postbacks,
  };
};
