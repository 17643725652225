import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ExporterEntity, ExportOverrideData, ExportedEntity } from '@playq/octopus2-apps';
import { CredentialsID } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

type UseExporterExportPayload = {
  entity: ExporterEntity;
  creds: CredentialsID;
  override: ExportOverrideData;
};

export const useExporterExport = (
  options?: UseMutationOptions<ExportedEntity, GenericFailure | Error, UseExporterExportPayload>
) =>
  useEitherMutation(({ entity, creds, override }) => services2.exporterService.export(entity, creds, override), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
