import { UseQueryOptions } from '@tanstack/react-query';

import { ExperimentsPolicy } from '@playq/octopus2-analytics';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

const policyExperimentsQueryKeys: unknown[] = ['corp', 'policies', 'experiments', 'query'];

export const usePolicyExperimentsQuery = (
  options?: UseQueryOptions<ExperimentsPolicy | undefined, GenericFailure | Error>
) => {
  return useEitherQuery(policyExperimentsQueryKeys, () => services2.experimentsAdminService.retrievePolicy(), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
};
