import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { FlowID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { removeFlowMutationKey } from './constants';

export const useFlowDelete = (options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, FlowID>) =>
  useEitherMutation((flow) => services2.flowsService.delete(flow), {
    mutationKey: removeFlowMutationKey,
    ...options,
  });
