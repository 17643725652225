import { GenericFailure } from '@playq/services-shared';
import { AppFlow } from '@playq/octopus2-liveops';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { flowQueryKeys } from './useFlowsQuery';
import { flowsService, flowsServiceName, createFlowMutationKey } from './constants';
import { ICreateFlowParams, IFlowCreateOptionsVariables } from './types';

export const useFlowCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<AppFlow, GenericFailure | Error, IFlowCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreateFlowParams, AppFlow, IInvokeScriptsHook>({
    serviceMethod: flowsService.create.bind(flowsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: flowsServiceName,
      mutationType: 'flow creation',
    },
    options: {
      removeQueriesKeys: flowQueryKeys,
      mutationKey: createFlowMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
