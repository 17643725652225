import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure, UserId, AppId } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export type UseRemoveUserArgs = {
  env: Environment;
  appId: AppId;
  userId: UserId;
};

export const useRemoveUserScores = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, UseRemoveUserArgs>
) => {
  return useEitherMutation(
    ({ env, appId, userId }) => services2.clerkSupportService.removeUser(env, appId, userId),
    options
  );
};
