import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure, UserId } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { AppLadderId } from '@playq/services-clerk';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export type UseRemoveUserForLadderArgs = {
  env: Environment;
  id: AppLadderId;
  userId: UserId;
};

export const useRemoveUserForLadder = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, UseRemoveUserForLadderArgs>
) => {
  return useEitherMutation(
    ({ env, id, userId }) => services2.clerkSupportService.removeUserForLadder(env, id, userId),
    options
  );
};
