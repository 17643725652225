import { WebEvent } from '@playq/octopus2-admin';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { updateWebEventMutationKey } from './constants';

export const useWebEventUpdate = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, WebEvent>
) => {
  return useEitherMutation((event) => services2.webEventsService.update(event), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: updateWebEventMutationKey,
    ...options,
  });
};
